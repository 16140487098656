<template>
    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Imprint (German Impressum)</div>
        <div class="text-700 text-center mb-5 line-height-3">A legal notice for publications is a legally required
            indication of the person(s) responsible for the content within the meaning of press law. It includes
            information about the publisher, the author, the editor or the editorial staff.</div>


        <!-- Imprint Content -->
        <div class="text-700 bg-white">
            <h3>Angaben gemäß § 5 TMG</h3>
            <p>
                Markus Haug <br>
                c/o Block Services <br>
                Stuttgarter Str. 106 <br>
                70736 Fellbach
            </p>

            <h3>Kontakt</h3>
            <p>
                E-Mail: <a href="mailto:hello@paperhub.so">hello@paperhub.so</a>
            </p>

            <h3>Redaktionell verantwortlich</h3>
            <p>
                Markus Haug <br>
                c/o Block Services <br>
                Stuttgarter Str. 106 <br>
                70736 Fellbach
            </p>

            <h3>Widerspruch gegen Werbe-Mails</h3>
            <p>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht
                ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen.
                Die
                Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
                von
                Werbeinformationen, etwa durch Spam-E-Mails, oder durch missbräuchliche Verwendung dieser Daten vor.
            </p>
        </div>
    </div>
    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '@/components/layout/PageFooter.vue';

export default {
    name: 'imprint-view',
    components: {
        PageFooter
    },
    data() {
        return {
            isAuthenticated: false
        }
    },
}
</script>
