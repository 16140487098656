import axios from 'axios';

export default class HighlightService {
  constructor(token) {
    this.endpoint = process.env.VUE_APP_BACKEND_URL + '/v1/library';
    this.contentType = 'application/json';
    this.token = 'Bearer ' + token;
  }

  getLibrary() {
    // send request to endpoint
    return axios
      .get(this.endpoint + '/Books', {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  getBookById(bookId) {
    return axios
      .get(this.endpoint + `/Book/${bookId}`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // delete library
  deleteLibrary(token) {
    return axios
      .delete(this.endpoint + '/delete', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // export book
  async exportBook(id, format) {
    return axios
      .post(
        this.endpoint + '/Book/export',
        {
          id: id,
          format: format,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
