<template>
    <div class="flex align-items-center justify-content-center flex-wrap align-self-center">
        <Button :label="$t('support')" @click="this.$router.push('/support')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button :label="$t('privacy_short')" @click="this.$router.push('/privacy')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button :label="$t('terms_short')" @click="this.$router.push('/tos')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button :label="$t('imprint')" @click="this.$router.push('/imprint')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button :label="$t('cookies')" @click="this.$router.push('/cookies')" severity="contrast"
            class="p-button-text text-sm"></Button>
    </div>
</template>