<template>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <div
      class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">

      <div class="flex align-items-center">
        <div class="flex align-items-center mb-3">
          <span class="text-3xl font-medium text-800">{{ $t('layout.library') }}</span>
        </div>
      </div>

      <span class="w-full md:w-auto">
        <InputText v-model="filters['global'].value" type="text" :placeholder="$t('search')" class="w-full md:w-auto" />
      </span>
    </div>

    <!-- Library Compontent  -->
    <Library v-if="!empty" v-bind:filters="filters" v-bind:library="library" v-bind:loading="loading"
      @open-book="openBook" />

    <!-- Empty Library -->
    <div v-if="empty" class="flex flex-column align-items-center mt-5">
      <div class="flex flex-column align-items-center">
        <img src="/images/empty-state/undraw_add_notes_re_ln36.svg" class="mt-4" style="width: 200px; height: 200px" />
        <span class="text-xl font-medium text-900 mt-4">{{ $t('libraryView.empty') }}</span>
        <span class="text-600 mt-2">{{ $t('libraryView.empty_descr') }}</span>
        <Button @click="this.$router.push('/sync')" class="mt-4" :label="$t('libraryView.sync')" outlined />
      </div>
    </div>
  </div>
</template>

<script>
import HighlightService from '../services/HighlightService';
import Library from '@/components/library/Library';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'home-view',
  components: {
    Library,
  },
  data() {
    return {
      library: this.library,
      loading: true,
      empty: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    }
  },
  highlightService: null,
  created() {
    this.token = localStorage.getItem('token');
    this.highlightService = new HighlightService(this.token);
  },
  mounted() {
    setTimeout(() => {
      this.highlightService.getLibrary().then(library => {
        this.library = library;
        // check if library is empty
        if (this.library.length === 0) {
          this.loading = false;
          this.empty = true;
        }
        this.loading = false;
      });
    }, 300);
  },
  methods: {
    openBook(book) {
      // navigate to book view
      this.$router.push({ path: `/library/${book._id}` });
    }
  }
}
</script>