<template>

    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Cookie Policy</div>
        <div class="text-700 text-center mb-5 line-height-3">Paperhub is using cookies to ensure that we give you the
            best
            experience on our website. Below you will find more information about the cookies we use and how you can
            control them.</div>

        <!-- Button for open Cookie Consent Dialog -->
        <div class="flex flex-wrap gap-3 justify-content-center">
            <Button @click="openCookieConsent" label="My Cookie Settings" severity="contrast" icon="pi pi-cog" />
        </div>

        <div class="text-700 bg-white">

            <h2>What are cookies?</h2>
            Cookies are small text files that are stored on your device to help us keep track of your
            activities and
            preferences over time. They enable features such as remembering your login details, maintaining
            your
            session, and providing relevant content based on your interests.

            <h2>What are they used for?</h2>
            Our use of cookies and similar technologies is essential to ensure the security and integrity of our
            services, to help us understand user behavior, and to optimize user experience. We also use these tools to
            offer you a more personalized experience and to support our advertising efforts.

            <h2>How do we use cookies?</h2>
            <p class="text-color font-bold">
                We use cookies, data and similar technologies to:
            </p>

            <ul class="list-disc">
                <li class="mb-2">
                    Provide, maintain, and improve Paperhub services.
                </li>
                <li class="mb-2">
                    Monitor service performance, track outages, analyze usage, and protect against spam, fraud,
                    and
                    abuse.
                </li>
                <li class="mb-2">
                    Understand your interactions with our services and gather site statistics to enhance user
                    experience. </li>
            </ul>


            <h2>How do you control cookies?</h2>
            You can control and/or delete cookies through your internet browser. If you want to delete cookies, you must
            first disable the cookies that are placed on your computer. You can do this through your internet browser
            settings.

            How do you control cookies from third parties?
            You can control and/or delete cookies from third parties through your internet browser settings.

            How do you control cookies from Paperhub?
            You can control and/or delete cookies from Paperhub through your internet browser settings.
        </div>
    </div>

    <!-- Cookie Consent Dialog -->
    <CookieConsent v-model:visible="cookieConsentDialog" />

    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '@/components/layout/PageFooter.vue';
import CookieConsent from '@/components/dialogs/CookieConsent';


export default {
    name: 'cookies-view',
    components: {
        PageFooter,
        CookieConsent
    },
    data() {
        return {
            isAuthenticated: false,
            cookieConsentDialog: false
        }
    },
    methods: {
        openCookieConsent() {
            this.cookieConsentDialog = true;
        },
        closeCookieConsent() {
            this.cookieConsentDialog = false;
        }
    }
}
</script>
