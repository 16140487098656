<template>
    <Toast />
    <div class="min-h-screen flex relative lg:static surface-section">
        <!-- Sidebar -->
        <!--class="hidden surface-section min-h-screen lg:block flex-shrink-0  fixed left-0 top-0 z-5 border-right-1 surface-border select-none"
            -->
        <div ref="sidebar" id="app-sidebar-1" class="sticky hidden surface-section h-screen lg:block flex-shrink-0 absolute  left-0 top-0 z-5
            border-right-1 surface-border select-none" style="width: 280px;">

            <!-- Logo -->
            <div class="flex flex-column h-full">
                <div class="flex align-items-center px-5 flex-shrink-0" style="height: 60px">
                    <img @click="this.$router.push('/')" class="cursor-pointer" src="/images/logos/logo.png"
                        alt="Paperhub Logo" height="30" />
                    <span @click="this.$router.push('/')" class="cursor-pointer flex ml-2 text-xl font-medium">Paperhub
                        <sub class="ml-1 text-xs text-color-secondary">{{ $t('beta') }}</sub></span>
                </div>

                <div class="overflow-y-auto">
                    <ul class="list-none p-3 m-0">
                        <!-- Library -->
                        <li>
                            <router-link v-ripple :class="[
                        'router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple',
                        $route.path === '/library' ? 'text-blue-500' : 'text-700',
                    ]" to="/library">
                                <i class="pi pi-book mr-2"></i>
                                <span class="font-medium">{{ $t('layout.library') }}</span>
                            </router-link>
                        </li>

                        <!-- BookMarks -->
                        <li v-if="!isSubmenuVisible" draggable="false">
                            <router-link v-ripple :class="[
                        'router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple',
                        $route.path === '/bookmarks/' ? 'text-blue-500' : 'text-700',
                    ]" to="/bookmarks">
                                <i class="pi pi-bookmark mr-2"></i>
                                <span class="font-medium">{{ $t('layout.bookmarks') }}</span>
                            </router-link>
                        </li>

                        <li v-if="isSubmenuVisible" draggable="false">
                            <router-link @click="toggleSubmenu" @dragleave="onDragLeave($event)" draggable="false"
                                @dragover="onDragOver($event)" @drop="onDrop($event, 'root')" @dragstart.prevent=""
                                v-ripple v-styleclass="{
                        selector: '@next',
                        enterClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup',
                    }" :class="[
                        'router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple',
                        $route.path === '/bookmarks' ? 'text-blue-500' : 'text-700',
                    ]" to="/bookmarks">
                                <i class="pi pi-bookmark mr-2"></i>
                                <span class="font-medium">Bookmarks</span>
                                <i class="pi pi-chevron-down ml-auto mr-1"></i>
                            </router-link>

                            <ul class="list-none py-0 pl-3 pr-0 m-0 overflow-y-hidden" v-show="isSubmenuVisible">
                                <!-- add new -->
                                <li draggable="false">
                                    <InputText type="text" v-model="newGroupName" class="border-none"
                                        :placeholder="$t('layout.new_group')" v-on:keyup.enter="onNewGroup" />
                                </li>

                                <li v-for="group in bookmarkGroups" :key="group._id" draggable="false"
                                    @dragleave="onDragLeave" @dragover="onDragOver"
                                    @drop="(event) => onDrop(event, group._id)">
                                    <router-link v-ripple :class="[
                        'router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple',
                        $route.path === `/bookmarks/${group._id}`
                            ? 'text-blue-500'
                            : 'text-700',
                    ]" :to="`/bookmarks/${group._id}`">
                                        <i class="pi pi-folder mr-2"></i>
                                        <span class="font-medium">{{ group.name }}
                                            <Tag v-if="group.public" class="ml-2" severity="secondary">
                                                <i class="pi pi-share-alt"></i>
                                            </Tag>
                                        </span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <!-- Sync -->
                        <li>
                            <router-link v-ripple :class="[
                        'router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple',
                        $route.path === '/sync' ? 'text-blue-500' : 'text-700',
                    ]" to="/sync">
                                <i class="pi pi-sync mr-2"></i>
                                <span class="font-medium">{{ $t('layout.sync') }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>

                <!-- Footer -->
                <div class="mt-auto">
                    <!-- <div class="overflow-y-auto flex-shrink-0 ">-->
                    <Divider></Divider>
                    <ul class="list-none p-3 m-0">
                        <!-- only for Beta -->
                        <li>
                            <a @click="this.betaFeedbackDialog = true" v-ripple
                                class="router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple text-700">
                                <i v-badge.primary class="pi pi-comments mr-2"></i>
                                <span class="font-medium">{{ $t('layout.beta_feedback') }}</span>
                            </a>
                        </li>
                        <li>
                            <router-link v-ripple
                                class="router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple text-700"
                                to="/support">
                                <i class="pi pi-question-circle mr-2"></i>
                                <span class="font-medium">{{ $t('layout.help_support') }}</span>
                            </router-link>
                        </li>
                        <li>
                            <a v-ripple
                                class="router-link flex align-items-center cursor-pointer p-3 border-round hover:surface-100 transition-duration-150 transition-colors p-ripple text-700"
                                @click="toggleLegalMenu" aria-haspopup="true" aria-controls="overlay_legalMenu">
                                <i class="pi pi-info-circle mr-2"></i>
                                <span class="font-medium">{{ $t('layout.legal_info') }}</span>
                                <Menu ref="legalMenu" id="overlay_legalMenu" :model="legalItems" :popup="true" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Main Content Area -->
        <div class="flex-column flex-auto">
            <!-- Navbar -->
            <div class="navbar fixed z-1 w-full justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border"
                style="height: 60px;">
                <!-- User menu-->
                <div class="flex">
                    <a v-ripple class="cursor-pointer block lg:hidden text-700 mr-3 mt-3 p-ripple" v-styleclass="{
                        selector: '#app-sidebar-1',
                        enterClass: 'hidden',
                        enterActiveClass: 'fadeinleft',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'fadeoutleft',
                        hideOnOutsideClick: true,
                    }">
                        <i class="pi pi-bars text-xl" style="color: black"></i>
                    </a>
                </div>

                <ul
                    class="list-none p-0 m-0 lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                    <li class="border-top-1 fixed top-0 right-0 surface-border lg:border-top-none lg:mt-1">
                        <a label="Toggle" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" v-ripple
                            class="flex p-3 lg:px-4 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple">
                            <Avatar v-if="!avatar_url" icon="pi pi-user" shape="circle" />
                            <Avatar v-if="avatar_url" :image="avatar_url" shape="circle" />
                            <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />

                            <span v-if="user" class="text-medium p-2">{{ user.name }}</span>
                        </a>
                    </li>
                </ul>

            </div>

            <div class="p-5 flex-column flex-auto mt-5">

                <!-- Here is the router view component -->
                <router-view @dragstart="toggleDragVisibility" @dragend="toggleDragVisibility" />
            </div>

        </div>
    </div>

    <!-- beta Feedback Liary dialog-->
    <Dialog v-model:visible="betaFeedbackDialog" appendTo="body" modal :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :header="$t('layout.beta_feedback_dialog.title')"
        :draggable="false" :resizable="false">
        <template #header>
            <div class="flex flex-column gap-2">
                <h1 class="m-0 text-900 font-semibold text-xl line-height-3">
                    {{ $t('layout.beta_feedback_dialog.title') }}
                </h1>
            </div>
        </template>
        <Message :closable="false" :sticky="sticky" icon="pi pi-exclamation-triangle"> {{
                        $t('layout.beta_feedback_dialog.note')
                    }}
        </Message>
        <!-- Success message -->
        <Message v-if="sendBetaSuccess" severity="success">{{ $t('layout.beta_feedback_dialog.sent') }}</Message>

        <!-- Error message -->
        <Message v-if="sendBetaError" severity="error">{{ $t('layout.beta_feedback_dialog.not_sent') }}</Message>

        <form class="flex flex-column gap-3 mt-3">
            <div>
                <label for="verify" class="block mb-1 text-color text-base">
                    {{ $t('layout.beta_feedback_dialog.your_feedback') }}</label>

                <Textarea v-model="feedback" rows="5" cols="30" class="w-full" />
            </div>
        </form>
        <template #footer>
            <Button :label="$t('layout.beta_feedback_dialog.send')" @click="sendFeedback" severity="primary"
                class="p-button-text"></Button>
        </template>
    </Dialog>

    <!-- Welcome Tour Dialog -->
    <Dialog v-model:visible="showWelcomeTourDialog" :closable="false" modal
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50rem' }">
        <Stepper linear="false">
            <!-- Welcome Start -->
            <StepperPanel header="">
                <template #content="{ nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/hello.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.title') }}, {{ user.name }}!
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.descr') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button type="button" :label="$t('layout.welcome_tour.skip')" severity="secondary" outlined
                            @click="endTour"></Button>

                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Overview -->
            <StepperPanel header="">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/overview.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step1.title') }}
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step1.descr') }}

                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Integrations -->
            <StepperPanel header="">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/integrations.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step2.title') }}
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step2.descr') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Library -->
            <StepperPanel header="">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/library.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step3.title') }}
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step3.descr') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Export -->
            <StepperPanel header="">
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/export.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step4.title') }}
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step4.descr') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Bookmark  -->
            <StepperPanel header="" end>
                <template #content="{ prevCallback, nextCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/bookmark.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step5.title') }}
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step5.descr') }}
                            </p>
                            <Button :label="$t('layout.install_chrome')" @click="installChromeExtension"
                                severity="contrast" />
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.next')" icon="pi pi-arrow-right" iconPos="right"
                            @click="nextCallback" />
                    </div>
                </template>
            </StepperPanel>

            <!-- Finish -->
            <StepperPanel header="" end>
                <template #content="{ prevCallback }">
                    <div class="flex flex-column justify-content-center align-items-center gap-5">
                        <div>
                            <img src="/images/welcome/done.svg" alt="Image"
                                class="w-full md:w-30rem align-items-center justify-content-center" />
                        </div>

                        <div class="text-center">
                            <div class="mb-3 font-bold text-2xl text-800">
                                {{ $t('layout.welcome_tour.step6.title') }}, {{ user.name }}!
                            </div>
                            <p class="text-700 text-xl mt-0 mb-6">
                                {{ $t('layout.welcome_tour.step6.descr') }}
                            </p>
                        </div>
                    </div>
                    <div class="flex pt-4 justify-content-between">
                        <Button :label="$t('layout.welcome_tour.back')" severity="secondary" icon="pi pi-arrow-left"
                            @click="prevCallback" />
                        <Button :label="$t('layout.welcome_tour.end')" @click="endTour" />
                    </div>
                </template>
            </StepperPanel>
        </Stepper>
    </Dialog>
</template>

<script>
import Menu from 'primevue/menu';
import ContactService from '@/services/ContactService';
import authService from '@/services/AuthService';
import bookmarkService from '@/services/BookmarkService';

import { mapActions, mapState } from 'vuex';

export default {
    name: 'app-layout',
    components: {
        Menu,
    },
    data() {
        return {
            user: '',
            newGroupName: '',
            showWelcomeTourDialog: false,
            feedback: '',
            sendBetaSuccess: false,
            sendBetaError: false,
            betaFeedbackDialog: false,
            isBookmarkGroupPage: false,
            droppedItem: null,
            isDragging: false,
            legalItems: [
                {
                    label: this.$t('terms'),
                    command: () => {
                        this.$router.push('/tos');
                    },
                },
                {
                    label: this.$t('privacy'),
                    command: () => {
                        this.$router.push('/privacy');
                    },
                },
                {
                    label: this.$t('cookies'),
                    command: () => {
                        this.$router.push('/cookies');
                    },
                },
                {
                    label: this.$t('imprint'),
                    command: () => {
                        this.$router.push('/imprint');
                    },
                },
            ],
            items: [
                {
                    label: this.$t('account'),
                    items: [
                        {
                            label: this.$t('layout.settings'),
                            icon: 'pi pi-cog',
                            command: () => {
                                this.$router.push('/settings');
                            },
                        },
                        {
                            label: this.$t('layout.logout'),
                            icon: 'pi pi-sign-out',
                            command: () => {
                                this.doLogout();
                            },
                        },
                    ],
                },
            ],
        };
    },
    computed: {
        ...mapState(['token', 'user', 'bookmarkGroups']),
        isSubmenuVisible() {
            return this.$route.path.startsWith('/bookmarks');
        },
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.token = localStorage.getItem('token');
        this.avatar_url = localStorage.getItem('avatar_url') || null;
        this.contactService = new ContactService();
        this.authService = new authService();

        this.bookmarkService = new bookmarkService(this.token, null);

        // fetch bookmark groups for authenticated users to display in navbar
        if (this.$route.meta.requiresAuth === true) {
            this.$store.dispatch('fetchBookmarkGroups');
        }

        // show welcome tour
        if (this.user && this.user.lastSeenVersion === '0.0.0') {
            this.showWelcomeTourDialog = true;
        }

    },
    methods: {
        ...mapActions(['doLogout', 'fetchUserProfile']),

        toggle(event) {
            this.$refs.menu.toggle(event);
        },
        allowDrop(event) {
            event.preventDefault(); // Allow drop by preventing default behavior
        },
        onDragOver(event) {
            event.preventDefault(); // Necessary to allow dropping

            // Find the closest <li> element and ensure it is the root <li>
            let targetElement = event.currentTarget;

            // Add the 'dragover' class to the root <li> element only
            if (targetElement.tagName === 'LI') {
                targetElement.classList.add('dragover');
                // Check if the target element has a child <i> and change its icon
                const icon = targetElement.querySelector('i');
                if (icon) {
                    icon.classList.remove('pi-folder'); // Remove a class to change the icon, example: 'pi-hover'
                    icon.classList.add('pi-folder-open'); // Add a class to change the icon, example: 'pi-hover'
                }
            }
        },

        onDragLeave(event) {
            event.preventDefault(); // Necessary to allow dropping

            // Find the closest <li> element and ensure it is the root <li>
            let targetElement = event.currentTarget;

            // Add the 'dragover' class to the root <li> element only
            if (targetElement.tagName === 'LI') {
                targetElement.classList.remove('dragover');

                const icon = targetElement.querySelector('i');
                if (icon) {
                    icon.classList.remove('pi-folder-open'); // Remove a class to change the icon, example: 'pi-hover'
                    icon.classList.add('pi-folder'); // Add a class to change the icon, example: 'pi-hover'
                }
            }

            this.toggleDragVisibility();
        },
        toggleDragVisibility() {
            // this.isDragging = !this.isDragging;
        },

        toggleSubmenu() {
            this.isSubmenuVisible = !this.isSubmenuVisible;
        },
        onDrop(event, group) {
            try {
                event.preventDefault(); // Prevent default behavior

                const bookmarkJson = event.dataTransfer.getData('application/json');
                const bookmark = JSON.parse(bookmarkJson);

                this.bookmarkService
                    .moveBookmark(bookmark._id, group)
                    .then(() => {
                        // refresh bookmarks page
                        this.$store.dispatch('fetchBookmarks');
                    })
                    .catch((error) => {
                        if (error.response.data.errorCode === 'bookmark_exists') {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('error'),
                                detail: this.$t('bookmarksView.already_exists'),
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: this.$t('error'),
                                detail: this.$t('error_occurred'),
                                life: 3000,
                            });
                        }
                    });

                this.onDragLeave(event);
                this.toggleDragVisibility();
            } catch (error) {
                return;
            }
        },

        onNewGroup() {
            if (!this.newGroupName) {
                return;
            }

            this.bookmarkService
                .createBookmarkGroup(this.newGroupName)
                .then((group) => {
                    this.bookmarkGroups.push(group);
                    this.newGroupName = '';
                })
                .catch((error) => {
                    if (error.response.data.errorCode === 'bookmark_group_exists') {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('bookmarksView.group_exists'),
                            life: 3000,
                        });
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$t('error'),
                            detail: this.$t('error_occurred'),
                            life: 3000,
                        });
                    }
                    this.newGroupName = '';
                });
        },

        sendFeedback(e) {
            e.preventDefault();
            this.contactService
                .sendBetaFeedback(this.user.name, this.user.email, this.feedback)
                .then(() => {
                    this.sendBetaSuccess = true;
                    this.feedback = null;
                })
                .catch(() => {
                    this.sendBetaError = true;
                });
        },

        toggleLegalMenu(event) {
            this.$refs.legalMenu.toggle(event);
        },

        installChromeExtension() {
            window.open(
                'https://chromewebstore.google.com/detail/paperhub/dkjijcajnbndaimljifncofckenomedn',
                '_blank'
            );
        },

        async endTour() {
            // send post request to update lastSeenVersion
            try {
                await this.authService.updateLastSeenVersion(
                    process.env.VUE_APP_VERSION,
                    this.token
                );

                this.user = await this.authService.fetchUserProfile(this.token);
                localStorage.setItem('user', JSON.stringify(this.user));
            } catch (error) {
                console.log(error);
            }
            this.showWelcomeTourDialog = false;
        },
    },
};
</script>

<style scoped>
.router-link {
    color: #fff;
    padding-right: 5px;
    text-decoration: none;
}

/* Add this to your component's style block or a global stylesheet */
.drop-zone {
    height: 200px;
    width: 100%;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, border-color 0.3s;
    /* Smooth transition */
}

.dragover {
    background-color: rgba(0, 0, 0, 0.1);
    /* Light background color */
    /* border-color: #007bff; */
    /* Highlight border color */
}

/* Ensure the navbar takes into account the sidebar width */
.navbar {
    padding-left: 280px;
    /* Sidebar width */
}

@media (max-width: 1024px) {

    /* On smaller screens, remove padding since sidebar will be hidden */
    .navbar {
        padding-left: 0;
    }
}

/* Adjust for smaller screens where the sidebar might be hidden */
@media (max-width: 1024px) {
    .main-content {
        padding-left: 0;
    }
}
</style>
