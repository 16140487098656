<template>

    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Privacy Policy</div>
        <div class="text-700 text-center mb-5 line-height-3">Privacy of personal data is of highest importance to us. We
            have formulated this privacy statement to demonstrate our strong commitment to the protection of personal
            data and privacy. This document sets out how we process information that can be used to identify
            individuals.</div>

        <!-- Privacy Policy Content -->
        <div class="text-700 bg-white">

            <h1>Privacy Policy</h1>
            <h2>1. Overview of Data Protection</h2>
            <h4>General Information
            </h4>
            <p>
                The following notes provide a simple overview of what happens to your personal data when you visit this
                website. Personal data is any data by which you can be personally identified. Detailed information on
                data protection can be found in our privacy policy below this text.
            </p>

            <h4>Data Collection on This Website</h4>
            <p>
                <strong>Who is responsible for data collection on this website?</strong><br>
                The data processing on this website is carried out by the website operator. You can find their contact
                details in the section “Notice concerning the responsible party” in this privacy policy.
            </p>
            <p>
                <strong>How do we collect your data?</strong><br>
                Your data is collected, firstly, by you providing it to us. This can, for example, be data that you
                enter into a contact form. Other data is collected automatically or after your consent by our IT systems
                when you visit the website. This is primarily technical data (e.g., internet browser, operating system,
                or the time of page access). The collection of this data occurs automatically as soon as you enter this
                website.
            </p>
            <p>
                <strong>What do we use your data for?</strong><br>
                Part of the data is collected to ensure the proper functioning of the website. Other data can be used to
                analyze your user behavior.
            </p>
            <p>
                <strong>What rights do you have regarding your data?</strong><br>
                You have the right to obtain information about the origin, recipient, and purpose of your stored
                personal data at any time, free of charge. You also have the right to request the correction or deletion
                of this data. If you have given consent for data processing, you can revoke this consent at any time for
                the future. Furthermore, you have the right to request the restriction of the processing of your
                personal data under certain circumstances. You also have the right to lodge a complaint with the
                competent supervisory authority. You can contact us at any time for further questions regarding data
                protection.
            </p>
            <p>
                <strong>Analytics and Third-Party Tools</strong><br>
                When visiting this website, your surfing behavior may be statistically analyzed. This is primarily done
                with so-called analysis programs. Detailed information on these analysis programs can be found in the
                following privacy policy.
            </p>

            <h2>3. General Information and Mandatory Disclosures</h2>
            <h4>Data Protection</h4>
            <p>
                The operators of this website take the protection of your personal data very seriously. We treat your
                personal data confidentially and in accordance with the statutory data protection regulations and this
                privacy policy.

                When you use this website, various personal data is collected. Personal data is data by which you can be
                personally identified. This privacy policy explains what data we collect and what we use it for. It also
                explains how and for what purpose this happens.

                We point out that data transmission over the internet (e.g., when communicating by email) can have
                security vulnerabilities. Complete protection of data against access by third parties is not possible.
            </p>
            <p>
                <strong>Notice Concerning the Responsible Party</strong><br>
                The responsible party for data processing on this website is:
                <br><br>
                Markus Haug <br>
                c/o Block Services <br>
                Stuttgarter Str. 106 <br>
                70736 Fellbach <br>
                E-Mail: <a href="mailto:hello@paperhub.so">hello@paperhub.so</a>
                <br><br>
                The responsible party is the natural or legal person who alone or jointly with others determines the
                purposes and means of processing personal data (e.g., names, email addresses, etc.).
            </p>

            <h4>Storage Period</h4>
            <p>
                Unless a more specific storage period has been mentioned within this privacy policy, your personal data
                will remain with us until the purpose for the data processing no longer applies. If you assert a
                legitimate request for deletion or revoke your consent to data processing, your data will be deleted
                unless we have other legally permissible reasons for storing your personal data (e.g., tax or commercial
                law retention periods); in the latter case, the deletion will occur after these reasons cease to apply.
            </p>

            <h4>Notice on Data Transfer to the USA and Other Third Countries</h4>
            <p>
                We use tools from companies based in the USA or other countries with inadequate data protection levels.
                When these tools are active, your personal data may be transferred to these countries and processed
                there. We point out that in these countries, a level of data protection comparable to that in the EU
                cannot be guaranteed. For example, U.S. companies are required to provide personal data to security
                authorities without the possibility of legal action against this for the data subject. Therefore, it
                cannot be excluded that U.S. authorities (e.g., intelligence services) may process, evaluate, and
                permanently store your data located on U.S. servers for surveillance purposes. We have no influence on
                these processing activities.
            </p>

            <h4>Revocation of Your Consent to Data Processing</h4>
            <p>
                Many data processing operations are only possible with your explicit consent. You can revoke consent
                that has already been given at any time. The legality of the data processing carried out before the
                revocation remains unaffected by the revocation.
            </p>

            <h4>Right to Object to Data Collection in Special Cases and to Direct Advertising (Art. 21 GDPR))
            </h4>
            <p>WHEN DATA PROCESSING IS BASED ON ART. 6 PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE
                PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS
                ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE
                FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA
                UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING THAT OUTWEIGH YOUR INTERESTS,
                RIGHTS, AND FREEDOMS, OR THE PROCESSING IS FOR THE ASSERTION, EXERCISE, OR DEFENSE OF LEGAL CLAIMS
                (OBJECTION PURSUANT TO ART. 21 PARA. 1 GDPR).

                IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY
                TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR SUCH MARKETING; THIS ALSO APPLIES TO
                PROFILING INSOFAR AS IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL NO
                LONGER BE USED FOR DIRECT MARKETING PURPOSES (OBJECTION PURSUANT TO ART. 21 PARA. 2 GDPR).</p>
            <h4>Right to Lodge a Complaint with a Supervisory Authority</h4>
            <p>
                In the event of violations of the GDPR, data subjects have the right to lodge a complaint with a
                supervisory authority, particularly in the Member State of their habitual residence, place of work, or
                the place of the alleged violation. The right to lodge a complaint exists without prejudice to other
                administrative or judicial remedies.
            </p>

            <h4>Right to Data Portability
            </h4>
            <p>
                You have the right to have data that we process based on your consent or in the performance of a
                contract automatically processed, delivered to you or to a third party in a commonly used,
                machine-readable format. If you request the direct transfer of the data to another responsible party,
                this will only be done to the extent that it is technically feasible.
            </p>

            <h4>SSL or TLS Encryption</h4>
            <p>
                For security reasons and to protect the transmission of confidential content, such as orders or
                inquiries that you send to us as the site operator, this site uses SSL or TLS encryption. You can
                recognize an encrypted connection by the fact that the address line of the browser changes from
                “http://” to “https://” and by the lock symbol in your browser line.

                If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
            </p>

            <h4>Information, Deletion, and Correction</h4>
            <p>
                Within the scope of the applicable legal provisions, you have the right to free information about your
                stored personal data, its origin and recipient, and the purpose of data processing and, if necessary, a
                right to correct or delete this data. The deletion of your user data or your entire user account can be
                carried out in your account settings, once logged in to the Service. For this purpose, as well as for
                further questions on the subject
                of personal data, you can contact us at any time.
            </p>

            <h4>Right to Restrict Processing</h4>
            <p>
                You have the right to request the restriction of the processing of your personal data. You can contact
                us at any time for this purpose. The right to restrict processing exists in the following cases:
                <br>
            <ul>
                <li> If you dispute the accuracy of your personal data stored by us, we usually need time to verify
                    this. For the duration of the verification, you have the right to request the restriction of the
                    processing of your personal data.</li>
                <li>If the processing of your personal data was/is unlawful, you may request the restriction of data
                    processing instead of deletion.</li>
                <li>If we no longer need your personal data, but you need it to exercise, defend, or assert legal
                    claims, you have the right to request the restriction of the processing of your personal data
                    instead of deletion.</li>
                <li>If you have lodged an objection pursuant to Art. 21 para. 1 GDPR, a balance must be struck between
                    your interests and ours. As long as it has not yet been determined whose interests prevail, you have
                    the right to request the restriction of the processing of your personal data.</li>
            </ul>
            <br>
            If you have restricted the processing of your personal data, this data may only be processed – apart from
            its storage – with your consent or for the assertion, exercise, or defense of legal claims or for the
            protection of the rights of another natural or legal person or for reasons of important public interest of
            the European Union or a Member State.
            </p>

            <h2>4. Data Collection on This Website</h2>

            <h4>Inquiries by Email, Telephone or Mail</h4>
            <p>
                If you contact us by email, telephone, or mail, your inquiry, including all personal data resulting from
                it (name, inquiry), will be stored and processed by us for the purpose of handling your request. We do
                not share this data without your consent. The processing of this data is based on Art. 6(1)(b) GDPR,
                provided that your inquiry is related to the fulfillment of a contract or is necessary for the
                implementation of pre-contractual measures. In all other cases, the processing is based on our
                legitimate interest in effectively handling inquiries directed to us (Art. 6(1)(f) GDPR) or on your
                consent (Art. 6(1)(a) GDPR) if it was requested.

                The data you send us via contact inquiries will remain with us until you request its deletion, revoke
                your consent to storage, or the purpose for data storage no longer applies (e.g., after your request has
                been fully processed). Mandatory legal provisions—particularly statutory retention periods—remain
                unaffected.
            </p>

            <p>
                Source: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
            </p>
        </div>
    </div>

    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '@/components/layout/PageFooter.vue';

export default {
    name: 'privacy-view',
    components: {
        PageFooter
    },
    data() {
        return {
            isAuthenticated: false
        }
    },
}
</script>
