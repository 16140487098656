<template>
    <ReaderNavbar @enlargeFont="enlargeFont" @reduceFont="reduceFont" @georgia="georgia" @arial="arial"
        @opensans="opensans" @openDyslexic="openDyslexic" @toggleReaderColor="toggleReaderColor" />

    <div class="min-h-screen flex relative lg:static">
        <div class="min-h-screen flex flex-column flex-auto"
            :class="{ 'bg-white': this.$store.state.readerColor === 'light', 'dark-reader': this.$store.state.readerColor == 'dark', 'sepia-reader': this.$store.state.readerColor == 'sepia' }">
            <div class="p-5 flex flex-column flex-auto">
                <div class=" flex-auto"
                    :class="{ 'bg-white': this.$store.state.readerColor === 'light', 'dark-reader': this.$store.state.readerColor == 'dark', 'sepia-reader': this.$store.state.readerColor == 'sepia' }">
                    <!-- Here is the router view component -->
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReaderNavbar from '@/components/reader/ReaderNavbar.vue';

export default {
    name: 'App',
    data() {
        return {
            colors: {
                preBackground: '#f5f5f5',
                codeBackground: '#f5f5f5',
                blockquoteBorder: '#666',
            }
        }
    },
    components: {
        ReaderNavbar
    },
    created() {
        this.loadPreferences();
    },
    methods: {
        enlargeFont() {
            this.$store.commit('SET_READER_FONT_SIZE', this.$store.state.readerFontSize + 2);
            this.savePreferences();
        },
        reduceFont() {
            this.$store.commit('SET_READER_FONT_SIZE', this.$store.state.readerFontSize - 2);
            this.savePreferences();
        },
        georgia() {
            this.$store.commit('SET_READER_FONT_FAMILY', 'Georgia');
            this.savePreferences();
        },
        arial() {
            this.$store.commit('SET_READER_FONT_FAMILY', 'Arial');
            this.savePreferences();
        },
        opensans() {
            this.$store.commit('SET_READER_FONT_FAMILY', 'Open Sans');
            this.savePreferences();
        },
        openDyslexic() {
            this.$store.commit('SET_READER_FONT_FAMILY', 'OpenDyslexic');
            this.savePreferences();
        },
        toggleReaderColor(color) {
            this.$store.commit('SET_READER_COLOR', color);
            this.savePreferences();
        },
        savePreferences() {
            const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
            if (cookieConsent && cookieConsent.preferences === true) {
                const readerPreferences = {
                    fontSize: this.$store.state.readerFontSize,
                    fontFamily: this.$store.state.readerFontFamily,
                    readerColor: this.$store.state.readerColor,
                };
                localStorage.setItem('readerPreferences', JSON.stringify(readerPreferences));
            }
        },
        loadPreferences() {
            const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
            if (cookieConsent && cookieConsent.preferences === true) {
                const readerPreferences = JSON.parse(localStorage.getItem('readerPreferences'));
                if (readerPreferences) {
                    this.$store.commit('SET_READER_FONT_SIZE', readerPreferences.fontSize);
                    this.$store.commit('SET_READER_FONT_FAMILY', readerPreferences.fontFamily);
                    this.$store.commit('SET_READER_COLOR', readerPreferences.readerColor);
                }
            }
        },
    },
}

</script>

<style>
.dark-reader {
    background-color: #404040;
    color: #fff;
}

.sepia-reader {
    background-color: #F4ECE3;
    color: #000;
}
</style>