<template>
    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Attributions</div>
        <div class="text-700 text-center mb-5 line-height-3">A legal notice for publications is a legally required
            indication of the person(s) responsible for the content within the meaning of press law. It includes
            information about the publisher, the author, the editor or the editorial staff.</div>


        <div class="text-700 bg-white">
            <p>
            <ul>
                <li>Hero illustration on landing page is licensed under <a
                        href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a> by <a
                        href="https://home.streamlinehq.com/">Streamline</a>
                </li>
                <li>Texture backgrounds on landing page are licensed under <a
                        href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC BY 4.0</a> <a
                        href="https://www.figma.com/community/file/1234956803010807920">Clément Diouloufet</a> </li>
                <li>Illustrations by unDraw</li>
            </ul>
            </p>
        </div>
    </div>
    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '@/components/layout/PageFooter.vue';

export default {
    name: 'imprint-view',
    components: {
        PageFooter
    },
    data() {
        return {
            isAuthenticated: false
        }
    },
}
</script>
