<template>
    <div class="container" style="height: 100vh;">
        <div class="row" style=" margin-top: 5%">
            <div class=" flex align-items-center justify-content-center  align-self-center
        ">
                <Card style="width: 30rem; " breakpoints="1199px: 75vw, 575px: 90vw" class="shadow-2">
                    <template #content>
                        <div class="text-center mb-5">
                            <img @click="this.$router.push('/')" src="/images/logos/logo.png" alt="Image" height="50"
                                class="mb-3 cursor-pointer">
                            <div class="text-900 text-3xl font-medium mb-3">{{ $t('loginPage.welcome') }}</div>
                            <span class="text-600 font-medium line-height-3">{{ $t('loginPage.no_account') }}</span>
                            <router-link to="/signup"
                                class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
                                {{ $t('loginPage.create_today') }}
                            </router-link>
                        </div>

                        <!-- notification -->
                        <Message v-if="error" severity="warn">{{ error }}</Message>
                        <!-- Error Alert -->
                        <Message v-if="loginError" severity="error">{{ loginError }}</Message>

                        <form @submit.prevent="loginUser">
                            <label for="email1" class="block text-900 font-medium mb-2">{{ $t('loginPage.email')
                                }}</label>
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" />

                            <label for="password1" class="block text-900 font-medium mb-2">{{ $t('loginPage.password')
                                }}</label>
                            <InputText id="password1" v-model="password" type="password" class="w-full mb-3" />

                            <div class="flex align-items-center justify-content-between mb-6">
                                <router-link to="/password-reset"
                                    class="font-medium no-underline ml-1 text-blue-500 text-right cursor-pointer">{{
                                $t('loginPage.forgot_password') }}</router-link>
                            </div>
                            <Button type="submit" :label="$t('loginPage.login')" icon="pi pi-user"
                                class="w-full"></Button>
                        </form>
                    </template>
                </Card>
            </div>
            <div class="row">
                <!-- Legal Links -->
                <authFooter />
            </div>
        </div>
    </div>

</template>
<script>
import { mapState, mapActions } from 'vuex';
import Card from 'primevue/card';
import authFooter from '@/components/legal/authFooter.vue';

export default {
    name: 'login-view',
    components: {
        Card,
        authFooter
    },
    data: () => {
        return {
            email: "",
            password: "",
        };
    },
    computed: {
        ...mapState([
            'loggingIn',
            'loginError',
            'token'
        ])
    },
    created() {
        // check query params for "error"
        if (this.$route.query.error) {
            switch (this.$route.query.error) {
                case 'extension_login_required':
                    this.error = 'Please login to Paperhub to connect the extension.';
                    break;
                default:
                    break;
            }
        }
    },
    methods: {
        ...mapActions(['doLogin']),

        loginUser(e) {
            e.preventDefault();

            // Get the `next` parameter from the query
            const next = this.$route.query.next || '/'; // Default to '/' if no `next` parameter

            this.doLogin({ email: this.email, password: this.password, next: next });
        },
    },
};
</script>

<style scoped>
html {
    min-height: 100%;
    /* fill the screen height to allow vertical alignement */
    display: grid;
    /* display:flex works too since body is the only grid cell */
}

body {
    margin: auto;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height of the viewport */
    width: 100vw;
    /* Full width of the viewport */
    position: relative;
    /* No need for absolute positioning */

}
</style>