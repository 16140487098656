<template>
    <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
            <div class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7">
                <div class="text-center lg:text-right">
                    <div class="mt-6 mb-3 font-bold text-6xl text-900">Are you lost?</div>
                    <p class="text-700 text-3xl mt-0 mb-6">Sorry, we couldn't find the page.</p>
                    <Button class="p-button-text mr-2" label="Go Back" icon="pi pi-arrow-left"
                        @click="$router.go(-1)"></Button>
                    <Button v-if="isAuthenticated" label="Go to Dashboard" icon="pi pi-home"
                        @click="$router.push('/')"></Button>
                </div>
                <div>
                    <img src="/images/404.svg" alt="Image" class="w-full md:w-28rem" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAuthenticated: false
        }
    },
    created() {
        if (JSON.parse(localStorage.getItem('user')) && localStorage.getItem(
            'token')) {
            this.isAuthenticated = true;
        }
    }
}
</script>