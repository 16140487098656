import axios from 'axios';

export default class BookmarkService {
  constructor(token, accessToken) {
    this.endpoint = process.env.VUE_APP_BACKEND_URL + '/v1/bookmark';
    this.groupEndpoint = process.env.VUE_APP_BACKEND_URL + '/v1/bookmarkGroup';
    this.contentType = 'application/json';
    this.token = 'Bearer ' + token;
    this.accessToken = 'Bearer ' + accessToken;
  }

  getBookmarks() {
    // send request to endpoint
    return axios
      .get(this.endpoint, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  createBookmark(bookmark) {
    return axios
      .post(
        this.endpoint,
        {
          sourceUrl: bookmark.sourceUrl,
          group: bookmark.group ? bookmark.group : 'root',
          title: bookmark.title ? bookmark.title : null,
        },
        {
          headers: {
            Authorization: this.accessToken,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  deleteBookmarks(token) {
    return axios
      .delete(this.endpoint, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  deleteSingleBookmark(bookmarkId, groupId) {
    return axios
      .delete(this.endpoint + `/${groupId}/${bookmarkId}`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  moveBookmark(bookmarkId, groupId) {
    return axios
      .put(
        this.endpoint + `/move`,
        { bookmarkId: bookmarkId, toGroup: groupId },
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  renameBookmark(bookmarkId, newTitle) {
    return axios
      .put(
        this.endpoint + `/rename`,
        { bookmarkId: bookmarkId, newTitle: newTitle },
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // get bookmark groups
  getBookmarkGroups() {
    return axios
      .get(this.groupEndpoint, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // create bookmark group
  createBookmarkGroup(name) {
    return axios
      .post(
        this.groupEndpoint,
        { name: name },
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // delete single bookmark group
  deleteBookmarkGroup(groupId) {
    return axios
      .delete(this.groupEndpoint + `/${groupId}`, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // delete all bookmark groups
  deleteBookmarkGroups() {
    return axios
      .delete(this.groupEndpoint, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  publishBookmarkGroup(groupId) {
    return axios
      .post(this.groupEndpoint + `/publish`, groupId, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  unpublishBookmarkGroup(groupId) {
    return axios
      .post(this.groupEndpoint + `/unpublish`, groupId, {
        headers: {
          Authorization: this.token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  renameBookmarkGroup(groupId, newName) {
    return axios
      .put(
        this.groupEndpoint + `/rename`,
        {
          groupId: groupId,
          newName: newName,
        },
        {
          headers: {
            Authorization: this.token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  fetchSharedGroup(groupId) {
    return axios
      .get(this.groupEndpoint + `/shared/${groupId}`, {})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
