<template>
    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Terms of Service</div>
        <div class="text-700 text-center mb-5 line-height-3">The Terms of Service Agreement is the legal agreement
            between a service provider and a person who wishes to use the service. The person must agree to abide by the
            terms of use in order to use the service provided.</div>

        <div class="text-700 bg-white">

            <h2><strong>Acceptance of These Terms of Service</strong></h2>

            <p class="p4">The service provided at <a target="_blank" rel="noopener noreferrer"
                    href="https://paperhub.so">https://paperhub.so</a> (the “Site”), including all related content and
                features (collectively referred to as the “Service”), is offered by Markus Haug under German law
                (“Service Owner,” “we,” “us,” or “our”). By accessing, browsing, or using any part of the Service, you
                acknowledge that you have read, understood, and agree to be bound by these Terms of Service (“Terms”).
                If you do not accept these Terms, you must not access or use the Service.</p>

            <p class="p4">We reserve the right to modify these Terms at any time. Should we make changes, they will be
                posted on this page, and the date of the latest revision will be indicated at the top. You can always
                view the most current version of these Terms by visiting the “Terms” link on the Site. We may also
                notify you of significant changes via email, the Service interface, or other appropriate means. Your
                continued use of the Service following any changes to these Terms signifies your acceptance of the
                updated Terms. Therefore, we encourage you to review these Terms periodically. If you do not agree to
                the revised Terms, please discontinue using the Service immediately.</p>

            <p class="p4"><strong>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION REGARDING
                    YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.</strong></p>

            <p class="p3"><strong>Privacy</strong></p>

            <p class="p4">The Service Owner respects your privacy and is committed to protecting your personal
                information. Please refer to our Privacy Policy, which is accessible through the “Privacy” link on the
                Site, for more details. By using the Service, you consent to the collection, use, and disclosure of your
                information as described in the Privacy Policy.</p>

            <p class="p3"><strong>Additional Terms</strong></p>

            <p class="p4">Certain features of the Service may be subject to additional terms and conditions, which will
                be made available to you when you access those features. All such additional terms are incorporated by
                reference into these Terms.</p>

            <h2><strong>Access and Use of the Service</strong></h2>

            <p class="p5"><strong>Service Description</strong></p>

            <p class="p4">The Service is designed to help you manage, read, and annotate your digital content from
                various sources within a unified platform. The Service is intended to enhance your digital reading
                experience by allowing you to make informed reading choices, deepen comprehension, and streamline your
                review process.</p>

            <p class="p5"><strong>Your Registration Obligations</strong></p>

            <p class="p4">To access certain features of the Service, you may be required to register an account. You
                agree to provide and maintain accurate, current, and complete information about yourself during the
                registration process. Your registration data and other information you provide are subject to our
                Privacy Policy. Users under 16 years of age are not permitted to use the Service. If you are under 18
                years old, you may use the Service only with the involvement of a parent or guardian.</p>

            <p class="p5"><strong>Member Account, Password, and Security</strong></p>

            <p class="p4">You are responsible for maintaining the confidentiality of your account credentials and are
                fully accountable for any activities that occur under your account. You agree to immediately notify the
                Service Owner of any unauthorized use of your account or any other security breaches. The Service Owner
                will not be liable for any loss or damage arising from your failure to comply with this security
                obligation.</p>

            <p class="p5"><strong>Modifications to Service</strong></p>

            <p class="p4">The Service Owner reserves the right to modify, suspend, or discontinue the Service, or any
                part thereof, with or without notice. You agree that the Service Owner will not be liable to you or any
                third party for any such modification, suspension, or discontinuance.</p>

            <p class="p5"><strong>General Practices Regarding Use and Storage</strong></p>

            <p class="p4">You acknowledge that the Service Owner may establish general practices and limits regarding
                the use of the Service, including the maximum period of time that data will be retained and the maximum
                storage space allocated on our or our third-party providers’ servers. The Service Owner has no liability
                for the deletion or failure to store any data maintained or transmitted by the Service. We reserve the
                right to deactivate or delete accounts that have been inactive for an extended period.</p>

            <p class="p5"><strong>Feedback</strong></p>
            <p class="p4">We welcome your feedback, comments, and suggestions for improving the Service (“Feedback”).
                You acknowledge and explicitly agree that providing Feedback does not entitle you to any rights,
                ownership, or interest in the Service or the Feedback itself. All Feedback becomes the exclusive
                property of the Service Owner, and the Service Owner may use and disclose it in any way and for any
                purpose, without further notice or compensation to you, and without retaining any proprietary or other
                rights or claims. By providing Feedback, you assign all rights, titles, and interests (including, but
                not limited to, patents, copyrights, trade secrets, trademarks, and any other intellectual property
                rights) that you may have in the Feedback to the Service Owner.</p>

            <p class="p5"><strong>References to Third-Party Services and Content</strong></p>

            <p class="p4">The Service may display references or links to third-party content, services, and software.
                Such references do not imply any endorsement by the Service Owner, and you acknowledge that the Service
                Owner is not responsible for the content or services provided by these third parties.</p>

            <h2><strong>Conditions of Access and Use</strong></h2>

            <p class="p5"><strong>User Conduct and Hosting Provider (§ 10 TMG)</strong></p>

            <p class="p4">The Service Owner operates as a Hosting Provider under § 10 TMG (German Telemedia Act). This
                means that we provide the infrastructure and platform for users to upload, store, and share their
                content, but we do not monitor or have control over the content posted by users. As such, users are
                solely responsible for the content they upload, post, or share through the Service, and the Service
                Owner disclaims any liability for illegal content uploaded by users.</p>

            <p class="p4">You agree not to use the Service to:</p>

            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Upload any content
                that infringes on the intellectual property rights of others, is illegal, harmful, or violates any
                third-party rights.</p>
            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Transmit any viruses
                or malicious code.</p>
            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Engage in
                unsolicited advertising, spam, or any form of solicitation.</p>
            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Harass, threaten, or
                harm others.</p>
            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Impersonate any
                person or entity or misrepresent your affiliation with a person or entity.</p>
            <p class="p6"><span class="Apple-tab-span"></span>•<span class="Apple-tab-span"> </span>Interfere with the
                Service or violate any laws or regulations in connection with your use of the Service.</p>

            <p class="p4">The Service Owner reserves the right to investigate and take appropriate action against anyone
                who, in our sole discretion, violates these conditions, including removing the offending content,
                terminating accounts, and reporting to law enforcement.</p>

            <p class="p5"><strong>Competitors</strong></p>

            <p class="p4">Employees, contractors, agents, or affiliates of any company that offers products or services
                similar to the Service are not permitted to use or access the Service without the explicit written
                consent of the Service Owner. By accessing or using the Service, you represent and warrant that you are
                not acting on behalf of a competitor of the Service.</p>

            <p class="p5"><strong>Commercial Use</strong></p>

            <p class="p4">The Service is intended for your personal use. Unless explicitly authorized, you agree not to
                reproduce, distribute, license, sell, or commercially exploit any part of the Service.</p>
            <h2><strong>Intellectual Property Rights</strong></h2>

            <p class="p5"><strong>Service Content</strong></p>

            <p class="p4">The Service and its content, including text, graphics, logos, and software, are protected by
                intellectual property laws. You agree not to copy, distribute, or create derivative works from the
                Service content unless expressly authorized by the Service Owner.</p>

            <!-- <p class="p5"><strong>Trademarks</strong></p>

            <p class="p4">The “Paperhub” name and logo are trademarks of Markus Haug. All other trademarks displayed via
                the Service are the property of their respective owners. You are not granted any right or license to use
                any trademarks displayed on the Service without prior written permission.</p> -->

            <p class="p5"><strong>User Content</strong></p>

            <p class="p4">You retain ownership of the User Content you upload or post to the Service. However, by
                submitting User Content, you grant the Service Owner a worldwide, non-exclusive, royalty-free license to
                use, distribute, modify, and display such content in connection with the Service.</p>

            <p class="p4">The Service Owner may, at its discretion, remove any User Content that violates these Terms or
                is otherwise objectionable.</p>

            <p class="p5"><strong>Copyright Complaints</strong></p>

            <p class="p4">If you believe your work has been copied in a way that constitutes copyright infringement,
                please notify us at <a href="mailto:hello@paperhub.so">hello@paperhub.so</a>. We will take appropriate
                action under the German Copyright Act (Urheberrechtsgesetz) and other applicable laws.</p>
            <h2><strong>Third-Party Services and Websites</strong></h2>

            <p class="p4">The Service may link to or integrate with third-party services and websites. Your interaction
                with these third-party services is solely between you and the respective third party. The Service Owner
                is not responsible for the content, privacy practices, or any other aspect of third-party services.</p>

            <h2><strong>Indemnification</strong></h2>

            <p class="p4">To the extent permitted by applicable law, you agree to indemnify and hold harmless the
                Service Owner, its affiliates, and their respective officers, employees, and agents from any claims,
                damages, or expenses arising out of your use of the Service, your User Content, or your violation of
                these Terms.</p>

            <h2><strong>Limitation of Liability and Waiver of Claims</strong></h2>


            <h3><strong>Disclaimer of Warranties</strong></h3>

            <p class="p4">The Service is provided “as is” and “as available” without warranties of any kind. The Service
                Owner disclaims all warranties, whether express, implied, or statutory, including but not limited to the
                implied warranties of merchantability, fitness for a particular purpose, and non-infringement. The
                Service Owner does not guarantee that the Service will meet your requirements, be uninterrupted, or be
                error-free.</p>


            <h3><strong>Limitation of Liability</strong></h3>

            <p class="p4">To the fullest extent permitted by law, the Service Owner shall not be liable for any
                indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with
                your use of the Service. The Service Owner’s total liability to you for all claims shall not exceed the
                greater of €100 or the amount you have paid the Service Owner in the last six (6) months.</p>

            <h3><strong>Waiver of Claims</strong></h3>

            <p class="p4">You expressly waive any rights to claims or remedies beyond what is provided for in these
                Terms. This includes a waiver of the right to seek damages exceeding the liability limits set forth
                above.</p>

            <h3><strong>Class Action Waiver</strong></h3>

            <p class="p4">You and the Service Owner agree that any disputes will be resolved solely on an individual
                basis and that you waive your right to participate in any form of a class action lawsuit or
                class-wide arbitration. If this class action waiver is found to be unenforceable by a court of
                competent jurisdiction, then all disputes shall be resolved in a court of law and not through
                arbitration.</p>


            <h2><strong>Governing Law and Jurisdiction</strong></h2>

            <p class="p4">These Terms are governed by and construed in accordance with the laws of Germany. Any
                disputes
                arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of
                the
                courts in Germany.</p>

            <h2><strong>Termination</strong></h2>

            <p class="p4">The Service Owner reserves the right to suspend or terminate your account or access to the
                Service at its sole discretion, with or without notice, if you violate these Terms or engage in any
                fraudulent, abusive, or illegal activity.</p>

            <h2><strong>Severability</strong></h2>
            <p class="p4">If any provision of these Terms is found to be invalid, illegal, or unenforceable by a court
                of competent jurisdiction, such provision shall be enforced to the maximum extent permissible, and the
                remaining provisions of these Terms shall remain in full force and effect. The invalid or unenforceable
                provision will be deemed superseded by a valid, enforceable provision that most closely matches the
                intent of the original provision.</p>

            <h2><strong>General Provisions</strong></h2>

            <p class="p4">These Terms (together with the terms incorporated by reference herein) constitute the
                entire
                agreement between you and the Service Owner governing your access and use of the Service. If any
                provision of these Terms is found to be invalid by a court of competent jurisdiction, the invalidity
                of
                such provision shall not affect the validity of the remaining provisions, which shall remain in full
                force and effect.</p>
            <h2><strong>Contact Information</strong></h2>

            <p class="p4">If you have any questions about these Terms, please contact us at <a
                    href="mailto:hello@paperhub.so">hello@paperhub.so</a>.</p>
        </div>
    </div>
    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '@/components/layout/PageFooter.vue';

export default {
    name: 'tos-view',
    components: {
        PageFooter
    },
    data() {
        return {
            isAuthenticated: false
        }
    },
}
</script>
