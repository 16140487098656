/* eslint-disable vue/multi-word-component-names */
import { createApp, reactive } from 'vue';
import { setupI18n } from './i18n'; // i18n setup
import Vuex from 'vuex';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import router from './router';
import store from './store';

// Prime Vue
// import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/themes/aura-light-blue/theme.css';
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Rating from 'primevue/rating';
import Ripple from 'primevue/ripple';
import Sidebar from 'primevue/sidebar';
import Slider from 'primevue/slider';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import ToastService from 'primevue/toastservice';
import SplitButton from 'primevue/splitbutton';
import OverlayPanel from 'primevue/overlaypanel';
import Toast from 'primevue/toast';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Inplace from 'primevue/inplace';
import AnimateOnScroll from 'primevue/animateonscroll';
import ButtonGroup from 'primevue/buttongroup';

const app = createApp(App);

app.use(Vuex);
app.use(router);
app.use(store);
app.use(ToastService);

app.use(PrimeVue, {
  ripple: false,
});

// app.config.productionTip = false;
app.config.globalProperties.$appState = reactive({
  theme: 'lara-light-indigo',
  dark: true,
});

// Check and set the browser language from cookies if not already set
let browserLanguage = getBrowserLanguage();
if (!browserLanguage) {
  const usersLanguage = navigator.language || navigator.userLanguage || 'en';
  document.cookie = `lang=${usersLanguage}; path=/`; // Set the language to the user's language
  browserLanguage = usersLanguage;
} else {
  document.cookie = `lang=${browserLanguage}; path=/`; // Confirm the current setting
}

const i18n = setupI18n({
  locale: browserLanguage,
  fallbackLocale: 'en',
  messages: {
    en: require('./locales/en.json'),
    de: require('./locales/de.json'),
    fr: require('./locales/fr.json'),
    ja: require('./locales/ja.json'),
    zh: require('./locales/zh.json'),
    es: require('./locales/es.json'),
    ko: require('./locales/ko.json'),
  },
});

app.use(i18n);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('InlineMessage', InlineMessage);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Sidebar', Sidebar);
app.component('Slider', Slider);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('ToggleButton', ToggleButton);
app.component('SplitButton', SplitButton);
app.component('OverlayPanel', OverlayPanel);
app.component('Toast', Toast);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Stepper', Stepper);
app.component('StepperPanel', StepperPanel);
app.component('Inplace', Inplace);
app.component('ButtonGroup', ButtonGroup);

app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('animateonscroll', AnimateOnScroll);

app.mount('#app');

// Function to get the browser language from cookies
function getBrowserLanguage() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('lang=')) {
      return cookie.substring(5, cookie.length);
    }
  }
}
