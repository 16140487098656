<template>
    <!-- Hero -->
    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Do you need help?</div>
        <div class="text-700 text-center mb-5 line-height-3">Our support is looking forward to assist you in the
            following cases.</div>

        <div class="grid flex align-items-center justify-content-center">

            <div class="col-12 lg:col-3 p-3 ">
                <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                    <div style="width:48px;height:48px;border-radius:10px"
                        class="bg-blue-500 inline-flex align-items-center justify-content-center mr-3">
                        <i class="pi pi-info-circle text-white text-3xl"></i>
                    </div>
                    <div>
                        <span class="text-900 text-xl font-medium mb-2">Tech Support</span>
                        <p class="mt-1 mb-0 text-600 font-medium text-sm">Any problems with our platform or with our
                            connectors?</p>
                    </div>
                </div>
            </div>

            <div class="col-12 lg:col-3 p-3">
                <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                    <div style="width:48px;height:48px;border-radius:10px"
                        class="bg-blue-500 inline-flex align-items-center justify-content-center mr-3">
                        <i class="pi pi-code text-white text-3xl"></i>
                    </div>
                    <div>
                        <span class="text-900 text-xl font-medium mb-2">Website Issue</span>
                        <p class="mt-1 mb-0 text-600 font-medium text-sm">Found a bug? Just let us now.</p>
                    </div>

                </div>
            </div>
            <div class="col-12 lg:col-3 p-3">
                <div class="p-3 border-round shadow-2 flex align-items-center surface-card">
                    <div style="width:48px;height:48px;border-radius:10px"
                        class="bg-blue-500 inline-flex align-items-center justify-content-center mr-3">
                        <i class="pi pi-users text-white text-3xl"></i>
                    </div>
                    <div>
                        <span class="text-900 text-xl font-medium mb-2">Account</span>
                        <p class="mt-1 mb-0 text-600 font-medium text-sm">Any questions or problems with your account?
                            Reach out to us!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- FAQ -->
    <div id="#faq" class="surface-50 px-4 py-8 md:px-6 lg:px-8 overflow-hidden flex flex-wrap justify-content-center">

        <div class="flex lg:justify-content-center mb-5">
            <div class="col-12 md:col-3">
                <span class="block font-bold text-3xl mb-3">Frequently Asked Questions</span>
                <div class="text-700 line-height-3">If you can't find your question, please reach out to us.</div>
            </div>
            <div class="col-12 md:col-9 md:px-5">
                <Accordion :activeIndex="0">
                    <AccordionTab header="Can I use Paperhub for free?">
                        <div class="line-height-3">Yes you can use Paperhub free of charge.</div>
                    </AccordionTab>
                    <AccordionTab header="Do you store my Amazon Kindle password?">
                        <div class="line-height-3">No we do not store any of your Amazon credentials. Our browser
                            extension can sync your Kindle highlights without the need for sharing any credentials with
                            us. </div>
                    </AccordionTab>
                    <AccordionTab header="Where can I download the Chrome extension?">
                        <div class="line-height-3">We will guide you step by step once you enable the Kindle connector.
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>


    <div class="bg-white px-4 py-8 md:px-6 lg:px-9">
        <div class="font-bold text-5xl text-900 text-center mb-3">Get in touch with us</div>
        <div class="text-700 text-center mb-5 line-height-3">Please reach out to us if you have any questions. We will
            do
            our best to help you.</div>
        <ul class="list-none p-0 m-0 mt-6 text-700">
            <li class="flex align-items-center justify-content-center">
                <a href="mailto:hello@paperhub.so">
                    <Button icon="pi pi-inbox mr-2" label="hello@paperhub.so" />
                </a>
            </li>
        </ul>
    </div>



    <!-- Footer -->
    <PageFooter />
</template>

<script>
import PageFooter from '../components/layout/PageFooter.vue';

export default {
    name: 'support-view',
    components: {
        PageFooter
    },
    data() {
        return {
            isAuthenticated: false,
            name: '',
            email: '',
            emailSent: true
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.token = localStorage.getItem('token');

        if (this.user && this.token) {
            this.isAuthenticated = true;
            this.name = this.user.name;
            this.email = this.user.email;
        }
    }
}
</script>
