<template>

  <div v-if="!requiresAuth">
    <Navbar />

    <div class="surface-section box-layout">
      <router-view />
    </div>
  </div>

  <AppLayout v-if="requiresAuth && !this.$route.path.includes('/reader/')" />

  <ReaderLayout v-if="requiresAuth && this.$route.path.includes('/reader/')" />

  <Toast />

  <!-- Cookie Consent Dialog -->
  <CookieConsent v-model:visible="visible" />

  <!-- Vercel Speed Insights -->
  <SpeedInsights />
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AppLayout from '@/components/layout/AppLayout.vue';
import Navbar from '@/components/layout/NavbarLayout.vue';
import ReaderLayout from '@/components/reader/ReaderLayout.vue';

import CookieConsent from '@/components/dialogs/CookieConsent';

// Vercel analytics
import { inject } from '@vercel/analytics';
import { SpeedInsights } from '@vercel/speed-insights/vue';

export default {
  name: 'App',
  data() {
    return {
      visible: false,
    };
  },
  components: {
    AppLayout,
    ReaderLayout,
    Navbar,
    CookieConsent,
    SpeedInsights,
  },
  computed: {
    ...mapState(['token']),
    requiresAuth() {
      return this.$route.meta.requiresAuth;
    },
  },
  created() {
    this.fetchAccessToken();

    // vercel web analytics
    inject({
      beforeSend: (event) => {
        // Replace the URL of the event with a placeholder for a book view in the library
        // Check if the URL includes '/library/' followed by an ID (24 alphanumeric characters)
        const libraryIdPattern = /\/library\/[a-f0-9]{24}$/;
        if (libraryIdPattern.test(event.url)) {
          // Replace the specific ID with <book_id>
          event.url = '/library/<book_id>';
          return event; // Track the event with the modified URL
        }

        // check if the URL includes "/password-reset/change/?token="
        if (event.url.includes('/password-reset/change/?token=')) {
          event.url = '/password-reset/change/';
          return event;
        }

        // check if the URL includes "/activate/?token="
        if (event.url.includes('/activate/?token=')) {
          event.url = '/activate/';
          return event;
        }

        // check if the URL includes "/share/group/"
        if (event.url.includes('/share/group/')) {
          event.url = '/share/group/';
          return event;
        }

        // check if the URL inclues /reader/
        if (event.url.includes('/reader/')) {
          event.url = '/reader/';
          return event;
        }

        // Check if the URL includes '/bookmark/' followed by an ID (24 alphanumeric characters)
        const bookmarkGroupIdPattern = /\/bookmarks\/[a-f0-9]{24}$/;
        if (bookmarkGroupIdPattern.test(event.url)) {
          // Replace the specific ID with <book_id>
          event.url = '/bookmarks/<group_id>';
          return event; // Track the event with the modified URL
        }

        return event;
      },
    });
  },
  methods: {
    ...mapActions(['fetchAccessToken']),

  },
};
</script>

<style lang="scss">
@import './assets/styles/app/layout.scss';
</style>

<style>
@media screen and (min-width: 1660px) {
  .box-layout {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 1504px !important;
  }
}

@media screen and (min-width: 992px) {
  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}
</style>