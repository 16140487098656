<template>

    <section class="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div class="flex flex-wrap gap-3 justify-content-between align-items-center">
            <div class="flex flex-column gap-2">
                <h1 class="m-0 text-900 text-3xl font-medium line-height-3">{{ $t('settings.title') }}</h1>
                <p class="mt-0 mb-0 text-600 text-lg">{{ $t('settings.subtitle') }}</p>
            </div>
        </div>
        <Divider type="solid" />

        <div class="flex gap-5 flex-column-reverse md:flex-row">
            <div class="flex-auto p-fluid">

                <div class="flex flex-column align-items-left flex-or mb-4">
                    <span class="block font-medium text-900 mb-2">{{ $t('settings.profile_picture') }}</span>
                    <img :src="avatar_url" rounded class="h-7rem w-7rem" />
                    <Button @click="openGravatar" type="button" icon="pi pi-pencil"
                        class="p-button-rounded -mt-4 shadow-3 border-solid border-white"></Button>
                </div>

                <div class="mb-4">
                    <label for="name" class="block font-medium text-900 mb-2">{{ $t('settings.name') }}</label>
                    <InputText id="name" disabled type="text" v-model="user.name" />
                </div>

                <div class="mb-4">
                    <label for="email" class="block font-medium text-900 mb-2">{{ $t('settings.email') }}</label>
                    <InputText id="email" disabled type="email" v-model="user.email" />
                </div>

                <!-- Danger Zone section-->
                <div class="flex flex-wrap gap-3 justify-content-between align-items-center">
                    <div class="flex flex-column gap-2">
                        <h1 class="m-0 text-900 text-red-600 text-3xl font-medium line-height-3">{{
                    $t('settings.danger_zone') }}</h1>
                        <p class="mt-0 mb-0 text-600 text-lg">{{ $t('settings.danger_zone_descr') }}</p>
                    </div>
                </div>
                <Divider type="solid" />
                <div>

                    <Button :label="$t('settings.delete_library')" @click="deleteLibraryDialog = true" outlined
                        severity="danger" class="w-auto"></Button>

                    <Button :label="$t('settings.delete_bookmarks')" @click="deleteBookmarkDialog = true" outlined
                        severity="danger" class="w-auto ml-2"></Button>

                    <Button :label="$t('settings.delete_account')" @click="deleteProfileDialog = true" severity="danger"
                        class="w-auto ml-2"></Button>
                </div>
            </div>
        </div>

        <!-- Delete Library dialog-->
        <Dialog v-model:visible="deleteLibraryDialog" appendTo="body" modal :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :header="$t('settings.are_you_sure')"
            :draggable="false" :resizable="false">
            <template #header>
                <div class="flex flex-column gap-2">
                    <h1 class="m-0 text-900 font-semibold text-xl line-height-3">{{ $t('settings.are_you_sure') }}</h1>
                </div>
            </template>
            <Message severity="error" :closable="false" :sticky="sticky" icon="pi pi-exclamation-triangle">
                {{ $t('settings.dialog_importance') }}
            </Message>
            <form class="flex flex-column gap-3 mt-3">
                <div>
                    <p v-html="$t('settings.delete_library_dialog.p1')"></p>
                    <p>{{ $t('settings.delete_library_dialog.p2') }}</p>
                    <Divider type="solid" />
                </div>

                <div>
                    <label for="verify" class="block mb-1 text-color text-base"
                        v-html="$t('settings.delete_library_dialog.verify')"></label>
                    <span class="w-full">
                        <InputText name="verify" type="text" class="w-full" id="verify"
                            v-model="verificationTextLibrary" />
                    </span>
                </div>
            </form>
            <template #footer>
                <Button :label="$t('settings.delete_library_dialog.delete')" @click="handleDeleteLibrary"
                    severity="danger" :disabled="!canDeleteLibrary" class="p-button-text"></Button>
            </template>
        </Dialog>

        <!-- Delete Bookmark Dialog -->
        <Dialog v-model:visible="deleteBookmarkDialog" appendTo="body" modal :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :header="$t('settings.are_you_sure')"
            :draggable="false" :resizable="false">
            <template #header>
                <div class="flex flex-column gap-2">
                    <h1 class="m-0 text-900 font-semibold text-xl line-height-3">{{ $t('settings.are_you_sure') }}</h1>
                </div>
            </template>
            <Message severity="error" :closable="false" :sticky="sticky" icon="pi pi-exclamation-triangle">
                {{ $t('settings.dialog_importance') }}
            </Message>
            <form class="flex flex-column gap-3 mt-3">
                <div>
                    <p v-html="$t('settings.delete_bookmarks_dialog.p1')"></p>
                    <p>{{ $t('settings.delete_bookmarks_dialog.p2') }}</p>
                    <Divider type="solid" />
                </div>

                <div>
                    <label for="verify" class="block mb-1 text-color text-base"
                        v-html="$t('settings.delete_bookmarks_dialog.verify')"></label>
                    <span class="w-full">
                        <InputText name="verify" type="text" class="w-full" id="verify"
                            v-model="verificationTextBookmarks" />
                    </span>
                </div>
            </form>
            <template #footer>
                <Button :label="$t('settings.delete_bookmarks_dialog.delete')" @click="handleBookmarksLibrary"
                    severity="danger" :disabled="!canDeleteBookmarks" class="p-button-text"></Button>
            </template>
        </Dialog>


        <!-- Delete Account dialog-->
        <Dialog v-model:visible="deleteProfileDialog" appendTo="body" modal :style="{ width: '50rem' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :header="$t('settings.are_you_sure')"
            :draggable="false" :resizable="false">
            <template #header>
                <div class="flex flex-column gap-2">
                    <h1 class="m-0 text-900 font-semibold text-xl line-height-3">{{ $t('settings.are_you_sure') }}</h1>
                </div>
            </template>
            <Message severity="error" :closable="false" :sticky="sticky" icon="pi pi-exclamation-triangle">
                {{ $t('settings.dialog_importance') }}
            </Message>
            <form class="flex flex-column gap-3 mt-3">
                <div>
                    <p v-html="$t('settings.delete_account_dialog.p1')"></p>
                    <p>{{ $t('settings.delete_account_dialog.p2') }}</p>
                    <Divider type="solid" />
                </div>

                <div>
                    <label for="verify" class="block mb-1 text-color text-base"
                        v-html="$t('settings.delete_account_dialog.verify')"></label>
                    <span class="w-full">
                        <InputText name="verify" type="text" class="w-full" id="verify"
                            v-model="verificationTextAccount" />
                    </span>
                </div>
            </form>
            <template #footer>
                <Button :label="$t('settings.delete_account_dialog.delete')" @click=" handleDelete" severity="danger"
                    :disabled="!canDelete" class="p-button-text"></Button>
            </template>
        </Dialog>

    </section>
</template>

<script>
import AuthService from '@/services/AuthService';
import HighlightService from '@/services/HighlightService';
import BookmarksService from '@/services/BookmarkService';
import { mapActions } from 'vuex';


export default {
    name: 'settings-view',
    data() {
        return {
            user: '',
            deleteProfileDialog: false,
            deleteLibraryDialog: false,
            deleteBookmarkDialog: false,
            verificationTextLibrary: '',
            verificationTextAccount: '',
            verificationTextBookmarks: '',
        }
    },
    computed: {
        canDelete() {
            return this.verificationTextAccount === 'delete my account';
        },
        canDeleteLibrary() {
            return this.verificationTextLibrary === 'delete my library';
        },
        canDeleteBookmarks() {
            return this.verificationTextBookmarks === 'delete my bookmarks';
        }
    },
    created() {
        this.authService = new AuthService();
        this.user = JSON.parse(localStorage.getItem('user'));
        this.token = localStorage.getItem('token');
        this.highlightService = new HighlightService(this.token);
        this.bookmarksService = new BookmarksService(this.token, null);
        this.avatar_url = localStorage.getItem('avatar_url') || null;

    },
    methods: {
        ...mapActions(['doLogout']),

        handleDelete(e) {
            e.preventDefault();

            if (this.canDelete) {
                this.authService.deleteUserProfile(this.token)
                    .then(() => {
                        this.doLogout();
                    })
                    .catch(() => {
                        this.verificationTextAccount = null
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'An error occurred while deleting your account',
                            life: 3000
                        });
                    });
            }
        },

        handleDeleteLibrary(e) {
            e.preventDefault();

            if (this.canDeleteLibrary) {
                this.highlightService.deleteLibrary(this.token)
                    .then(() => {
                        this.deleteLibraryDialog = false;
                        this.verificationTextLibrary = null

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Library deleted',
                            detail: 'All your highlights have been deleted',
                            life: 3000
                        });

                    })
                    .catch(() => {
                        this.verificationTextLibrary = null
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'An error occurred while deleting your library',
                            life: 3000
                        });
                    });
            }
        },

        handleBookmarksLibrary(e) {
            e.preventDefault();

            if (this.canDeleteBookmarks) {
                this.bookmarksService.deleteBookmarks(this.token)
                    .then(() => {
                        this.deleteBookmarkDialog = false;
                        this.verificationTextBookmarks = null

                        this.$toast.add({
                            severity: 'success',
                            summary: 'Bookmarks deleted',
                            detail: 'All your bookmarks have been deleted',
                            life: 3000
                        });
                    })
                    .catch(() => {
                        this.verificationTextBookmarks = null
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'An error occurred while deleting your bookmarks',
                            life: 3000
                        });
                    });
            }
        },

        openGravatar() {
            window.open('https://gravatar.com/profile', '_blank');
        }
    }
}
</script>