<template>

    <div class="z-5 sticky py-3 px-4 md:px-6 lg:px-4 flex align-items-center justify-content-between fixed w-full"
        :class="{ 'bg-white': this.$store.state.readerColor === 'light', 'dark-reader': this.$store.state.readerColor == 'dark', 'sepia-reader': this.$store.state.readerColor == 'sepia' }"
        v-on:scroll="scroll">
        <!-- <img @click="this.$router.push('/')" class="cursor-pointer" src="/images/logos/logo.png" alt="Image" height="30"
            width="30" /> -->

        <Button v-tooltip.right="$t('reader.back')" severity="secondary" icon="pi pi-arrow-left"
            @click="this.$router.go(-1)" aria-label="Home" />


        <!-- toggle font settings -->
        <Button v-tooltip.left="$t('reader.font_settings')" label="Aa" severity="secondary" @click="toggleFontSettings"
            aria-label="Font settings" />

        <OverlayPanel ref="op" class="z-5">
            <div class="flex flex-column gap-3 w-15rem">
                <div class="mb-2">
                    <span class="font-medium text-900 block mb-2">{{ $t('reader.reader_color') }}</span>
                    <div class="flex flex-wrap gap-3">
                        <div class="flex align-items-center">
                            <RadioButton v-model="readerColor" inputId="ingredient1" name="light" value="light"
                                @update:modelValue="toggleReaderColor" />
                            <label for="ingredient1" class="ml-2">{{ $t('reader.light') }}</label>
                        </div>
                        <div class="flex align-items-center">
                            <RadioButton v-model="readerColor" inputId="ingredient2" name="sepia" value="sepia"
                                @update:modelValue="toggleReaderColor" />
                            <label for="ingredient2" class="ml-2">{{ $t('reader.sepia') }}</label>
                        </div>
                        <div class="flex align-items-center">
                            <RadioButton v-model="readerColor" inputId="ingredient3" name="dark" value="dark"
                                @update:modelValue="toggleReaderColor" />
                            <label for="ingredient3" class="ml-2 ">{{ $t('reader.dark') }}</label>
                        </div>
                    </div>

                    <span class="font-medium text-900 block mb-2">{{ $t('reader.font_size') }}</span>
                    <ButtonGroup class="w-full block">
                        <Button label="a" severity="secondary" @click="this.$emit('reduceFont')" />

                        <Button label="A" severity="secondary" @click="this.$emit('enlargeFont')" />
                    </ButtonGroup>

                    <span class="font-medium text-900 block mb-2 mt-2">{{ $t('reader.font_type') }}</span>
                    <div>
                        <Button label="Georgia" outlined
                            :severity="this.$store.state.readerFontFamily === 'Georgia' ? 'info' : 'secondary'"
                            class="block mt-2 w-full georgia" @click="this.$emit('georgia')" />

                        <Button label="Arial" outlined
                            :severity="this.$store.state.readerFontFamily === 'Arial' ? 'info' : 'secondary'"
                            class="block mt-2 w-full arial" @click="this.$emit('arial')" />

                        <Button label="Open Sans" outlined
                            :severity="this.$store.state.readerFontFamily === 'Open Sans' ? 'info' : 'secondary'"
                            class="block mt-2 w-full opensans" @click="this.$emit('opensans')" />

                        <Button label="Open Dyslexic" outlined
                            :severity="this.$store.state.readerFontFamily === 'OpenDyslexic' ? 'info' : 'secondary'"
                            class="block mt-2 w-full opendyslexic" @click="this.$emit('openDyslexic')" />
                    </div>


                </div>
                <div>
                    <span class="text-gray-400">{{ $t('reader.save_cookies') }}<router-link class="text-gray-400"
                            to="/cookies">{{ $t('reader.cookie_settings') }}</router-link></span>
                </div>
            </div>
        </OverlayPanel>


    </div>


</template>

<script>
export default {
    name: 'readerNavbar-layout',
    data() {
        return {
            fontSize: 16,
            readerColor: this.$store.state.readerColor,
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.scroll);
    },

    methods: {
        scroll() {
            this.$refs.op.hide();
            if (window.scrollY > 0) {
                this.$el.classList.add('border-bottom-1');
                this.$el.classList.add('border-200');

            } else {
                this.$el.classList.remove('border-bottom-1');
                this.$el.classList.remove('border-200');

            }
        },
        toggleFontSettings(event) {
            this.$refs.op.toggle(event);
        },
        toggleReaderColor(value) {
            this.$emit('toggleReaderColor', value);
        },
    },
};
</script>

<style scoped>
.navbar {
    display: grid;
    grid-template-columns: repeat(3, auto);
    width: 100%;
    background-size: contain;
}

.sticky {
    position: sticky;
    top: 0;
    width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
}

.georgia {
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.arial {
    font-family: Arial, sans-serif;
}

.opensans {
    font-family: 'Open Sans', sans-serif;
}

.opendyslexic {
    font-family: 'OpenDyslexic', sans-serif;
    font-size: 12px;
}
</style>