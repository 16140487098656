import axios from 'axios';

export default class ConnectorService {
  constructor() {
    this.endpoint = process.env.VUE_APP_BACKEND_URL + '/v1/';
    this.contentType = 'application/json';
  }

  sendBetaFeedback(name, email, feedback) {
    // send request to endpoint
    return axios
      .post(this.endpoint + 'sendBetaFeedback', {
        name: name,
        email: email,
        feedback: feedback,
      })
      .then((response) => {
        // Return the response data after the token has been handled
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
