<template>
    <div class="container" style="height: 100vh;">
        <div class="row" style=" margin-top: 5%">
            <div class=" flex align-items-center justify-content-center  align-self-center
        ">
                <Card style="width: 30rem; " breakpoints="1199px: 75vw, 575px: 90vw" class="shadow-2">
                    <template #content>
                        <div class="text-center mb-5">
                            <img @click="this.$router.push('/')" src="/images/logos/logo.png" alt="Image" height="50"
                                class="mb-3 cursor-pointer">
                            <div class="text-900 text-3xl font-medium mb-3">{{ $t('resetPWPage.title') }}</div>
                        </div>

                        <Message v-if="passwordResetSuccess" severity="success">{{ $t('resetPWPage.success') }}
                        </Message>

                        <Message v-if="passwordResetError" severity="error">{{ $t('resetPWPage.failed') }}</Message>

                        <form @submit.prevent="resetPassword">
                            <label for="password1" class="block text-900 font-medium mb-2">{{
                                $t('resetPWPage.new_password') }}</label>
                            <InputText id="password1" v-model="password1" type="password" class="w-full mb-3" />

                            <label for="password2" class="block text-900 font-medium mb-2">{{
                                $t('resetPWPage.confirm_password') }}</label>
                            <InputText id="password2" v-model="password2" type="password" class="w-full mb-3" />
                            <small class="flex mb-6" id="password-help">{{ $t('resetPWPage.hint') }}</small>

                            <Button type="submit" :label="$t('resetPWPage.reset')" class="w-full"></Button>
                        </form>
                    </template>
                </Card>
            </div>
            <div class="row">
                <!-- Legal Links -->
                <authFooter />
            </div>
        </div>
    </div>

</template>
<script>
import Card from 'primevue/card';
import AuthService from '@/services/AuthService'
import authFooter from '@/components/legal/authFooter.vue';

const authService = new AuthService();

export default {
    name: 'password-reset-view',
    components: {
        Card,
        authFooter
    },
    data: () => {
        return {
            password1: '',
            password2: '',
            passwordResetError: null,
            passwordResetSuccess: null
        };
    },
    methods: {
        resetPassword(e) {
            e.preventDefault();

            authService.resetPassword(
                this.$route.query.id,
                this.$route.query.token,
                this.password1,
                this.password2
            ).then((res) => {
                this.passwordResetSuccess = res
            }).catch((error) => {
                this.passwordResetError = error.response.data
            })
        }
    },
};
</script>

<style scoped>
html {
    min-height: 100%;
    /* fill the screen height to allow vertical alignement */
    display: grid;
    /* display:flex works too since body is the only grid cell */
}

body {
    margin: auto;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height of the viewport */
    width: 100vw;
    /* Full width of the viewport */
    position: relative;
    /* No need for absolute positioning */

}
</style>