<template>
  <div class="container" style="height: 100vh;">
    <div class="row" style=" margin-top: 5%">
      <div class=" flex align-items-center justify-content-center  align-self-center
      ">
        <Card style="width: 30rem; " breakpoints="1199px: 75vw, 575px: 90vw" class="shadow-2">
          <template #content>
            <div class="text-center mb-5">
              <img @click="this.$router.push('/')" src="images/logos/logo.png" alt="Image" height="50"
                class="mb-3 cursor-pointer" />
              <div class="text-900 text-3xl font-medium mb-3">
                {{ $t('signupPage.title') }}
              </div>
              <span class="text-600 font-medium line-height-3">{{ $t('signupPage.already_account') }}</span>
              <router-link to="/login" class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">
                {{ $t('signupPage.login') }}
              </router-link>
            </div>

            <!-- Success Alert -->
            <Message v-if="signupSuccessful" severity="success"> {{ $t('signupPage.confirm_email') }}
            </Message>

            <!-- Error Alert -->
            <Message v-if="signupError" severity="error">{{ signupError }}</Message>
            <Message v-if="termsMissing" severity="error">
              {{ $t('signupPage.terms_missing') }}</Message>

            <form @submit.prevent="signupUser">
              <label for="name" class="block text-900 font-medium mb-2">{{ $t('signupPage.name') }}</label>
              <InputText id="name" v-model="name" type="text" class="w-full mb-3" />
              <label for="email" class="block text-900 font-medium mb-2">{{ $t('signupPage.email') }}</label>
              <InputText id="email" v-model="email" type="text" class="w-full mb-3" />

              <label for="password" class="block text-900 font-medium mb-2">{{ $t('signupPage.password') }}</label>
              <InputText id="password" v-model="password" type="password" class="w-full mb-3" />
              <small class="flex mb-4" id="password-help">{{ $t('signupPage.password_hint') }}</small>

              <Checkbox v-model="terms" inputId="terms" name="terms" value="X" />
              <label for="terms" class="ml-2">
                {{ $t('signupPage.terms') }}
                <router-link to="/tos" class="font-medium no-underline text-blue-500 cursor-pointer mr-1 ml-1">{{
                $t('terms') }}</router-link>
                &amp;
                <router-link to="/privacy" class="font-medium no-underline text-blue-500 cursor-pointer ml-1">{{
                $t('privacy') }}</router-link>.
              </label>

              <Button type="submit" :label="$t('create_account')" icon="pi pi-user" class="mt-6 w-full"></Button>

              <div class="mt-2 flex justify-content-center flex-wrap">
                <p class="flex align-items-center justify-content-center">
                  {{ $t('signupPage.note') }}
                </p>
              </div>
            </form>
          </template>
        </Card>
      </div>
      <div class="row">
        <!-- Legal Links -->
        <authFooter />
      </div>
    </div>
  </div>

</template>
<script>
import { mapState, mapActions } from 'vuex';
import Card from 'primevue/card';
import authFooter from '@/components/legal/authFooter.vue';

export default {
  name: 'signup-view',
  components: {
    Card,
    authFooter,
  },
  data: () => {
    return {
      terms: null,
      name: '',
      email: '',
      password: '',
      termsMissing: null,
    };
  },
  computed: {
    ...mapState(['signingUP', 'signupSuccessful', 'signupError', 'token']),
  },
  methods: {
    ...mapActions(['doSignup']),

    signupUser(e) {
      e.preventDefault();
      if (!this.terms) {
        this.termsMissing =
          'Please agree to the terms of service and privacy policy.';
        return;
      }
      this.doSignup({
        name: this.name,
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>
html {
  min-height: 100%;
  /* fill the screen height to allow vertical alignement */
  display: grid;
  /* display:flex works too since body is the only grid cell */
}

body {
  margin: auto;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full height of the viewport */
  width: 100vw;
  /* Full width of the viewport */
  position: relative;
  /* No need for absolute positioning */

}
</style>
