import axios from 'axios';

export default class AuthService {
  endpoint = process.env.VUE_APP_BACKEND_URL + '/v1/auth';
  contentType = 'application/json';

  login(email, password) {
    return axios
      .post(this.endpoint + '/login', {
        email,
        password,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  activate(id, token) {
    return axios
      .post(this.endpoint + '/activate', {
        token,
        id,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  register(name, email, password) {
    return axios
      .post(this.endpoint + '/register', {
        name,
        email,
        password,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  requestPasswordReset(email) {
    return axios
      .post(this.endpoint + '/requestResetPassword', {
        email,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  resetPassword(id, token, password, passwordRepeat) {
    return axios
      .post(this.endpoint + '/reset-password', {
        id,
        token,
        password,
        passwordRepeat,
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // fetch user profile from /user endpoint
  fetchUserProfile(token) {
    return axios
      .get(this.endpoint + '/user', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // fetch accessToken for extension import
  fetchAccessToken(token) {
    return axios
      .get(this.endpoint + '/accessToken', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // delete user profile
  deleteUserProfile(token) {
    return axios
      .delete(this.endpoint + '/me', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }

  // update last seen version
  updateLastSeenVersion(lastVersion, token) {
    return axios
      .post(
        this.endpoint + '/lastSeenVersion',
        {
          lastVersion: lastVersion,
          token: token,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  }
}
