<!-- eslint-disable vue/no-mutating-props -->
<template>

    <DataTable stripedRows :value="library" :paginator="true" v-if="!loading" :rows="10" :rowHover="true" :dataKey="_id"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" filterDisplay="menu"
        :filters="filters" :globalFilter="globalFilter" :filterMode="'matchMode'" :rowClass="rowClass">

        <Column field="title" :header="$t('libraryView.title')" sortable>
            <template #body="{ data }">
                <div @click="$emit('open-book', data)" class="flex align-items-center book-item">
                    <img :src="getCoverUrl(data.integration, data.coverUrl, data.sourceUrl)" class="mr-4 cover-image"
                        :class="{ 'non-kindle-cover': data.integration !== 'kindle' }">
                    <span class="image-text">{{ data.title.split(':')[0].split("(")[0] }}</span>
                </div>
            </template>
        </Column>

        <Column field="author" :header="$t('libraryView.author')" sortable>
            <template #body="{ data }">
                <div @click="$emit('open-book', data)">
                    {{ data.author }}
                </div>
            </template>
        </Column>

        <Column field="numberOfHighlights" :header="$t('libraryView.highlight_count')" sortable>
            <template #body="{ data }">
                <div @click="$emit('open-book', data)">
                    {{ data.numberOfHighlights }}
                </div>
            </template>
        </Column>


        <template #empty>
            <p v-if="!loading">{{ $t('libraryView.title') }}</p>
        </template>

    </DataTable>

    <div v-if="loading">
        <DataTable autolayout="true" :value="dummy" headerClass="skeleton-header">
            <Column>
                <template #body>
                    <div class="flex align-items-center">
                        <Skeleton size="4rem" class="mr-4"></Skeleton>
                        <Skeleton></Skeleton>
                    </div>
                </template>
            </Column>
            <Column>
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column>
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Skeleton from 'primevue/skeleton';

export default {
    name: "highlight-list",
    props: ["library", "loading", "filters", "globalFilter"],
    components: {
        DataTable,
        Column,
        Skeleton
    },
    data() {
        return {
            dummy: new Array(7).fill({
                title: 'My new Book',
                author: 'John Doe',
                highlights: 5,
            }),
        }
    },
    methods: {
        rowClass() {
            return 'cursor-pointer';
        },
        getCoverUrl(integration, coverUrl) {
            if (integration === 'kindle') {
                this.imageUrl = coverUrl;
                return coverUrl;
            } else if (integration === 'instapaper') {
                // get open graph image from url
                return '/images/logos/instapaper.png';
            }
            return coverUrl; // Default fallback if needed TODO: change to default / missing image
        },
    }
}
</script>

<style scoped>
.is-complete {
    text-decoration: line-through;

}

.skeleton-header {
    display: none;
}

.cover-image {
    width: 55px;
    height: 63px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.non-kindle-cover {
    object-fit: cover;
}

.book-item :hover {
    cursor: pointer;
    text-decoration: underline;
}
</style>