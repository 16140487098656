<template>
    <Dialog :visible="visible" appendTo="body" modal :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :header="$t('cookie_consent.title')" :draggable="false"
        :resizable="false" :closable="false">
        <template #header>
            <div class="flex flex-column gap-2">
                <h1 class="m-0 text-900 font-semibold text-xl line-height-3">{{ $t('cookie_consent.title') }}</h1>
            </div>
        </template>

        <form class="flex flex-column gap-3">

            <TabView>
                <TabPanel :header="$t('cookie_consent.consent')">
                    <p class="text-color font-bold">
                        {{ $t('cookie_consent.consent_p1') }}
                    </p>

                    <ul class="list-disc">
                        <li class="mb-2">
                            {{ $t('cookie_consent.consent_li1') }}
                        </li>
                        <li class="mb-2">
                            {{ $t('cookie_consent.consent_li2') }}
                        </li>
                        <li class="mb-2">
                            {{ $t('cookie_consent.consent_li3') }} </li>
                    </ul>

                    <!-- Description -->
                    <p> {{ $t('cookie_consent.consent_p2') }}</p>

                    <p class="text-color">
                        {{ $t('cookie_consent.consent_p3') }} <router-link to="/privacy" target="_blank"
                            class="text-primary">{{
        $t('privacy') }}</router-link> {{ $t('and') }} <router-link to="/cookies" target="_blank"
                            class="text-primary">{{ $t('cookies') }}</router-link>.
                    </p>

                    <p class="text-color">
                        {{ $t('cookie_consent.consent_p4') }}
                    </p>

                    <!-- Cookie consent switches -->
                    <div class="grid mt-2">
                        <!-- Necessary -->
                        <div class="col border-solid surface-border border-1">
                            <div class="p-3 flex flex-column align-items-center justify-content-center font-bold">
                                <label class="block mb-2" for="switchNecessary">Necessary</label>
                                <InputSwitch @click="necessaryRequired()" class="mt-2" v-model="switchNecessary" />
                            </div>
                        </div>
                        <!-- Preferences -->
                        <div class="col border-solid surface-border border-1">
                            <div class="p-3 flex flex-column align-items-center justify-content-center font-bold">
                                <label class="block mb-2">Preferences</label>
                                <InputSwitch class="mt-2" v-model="switchPreferences" />
                            </div>
                        </div>
                        <!-- Statistics -->
                        <div class="col border-solid surface-border border-1">
                            <div class="p-3 flex flex-column align-items-center justify-content-center font-bold">
                                <label class="block mb-2">Statistics</label>
                                <InputSwitch class="mt-2" v-model="switchStatistics" />
                            </div>
                        </div>
                    </div>

                </TabPanel>
                <TabPanel :header="$t('cookie_consent.details')">
                    <p class="m-0">
                        {{ $t('cookie_consent.details_text') }}
                    </p>

                    <!-- Details about cookies -->
                    <Accordion class="mt-2" expandIcon="pi pi-angle-down" collapseIcon="pi pi-angle-up">
                        <AccordionTab>
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <span class="font-bold white-space-nowrap">Necessary</span>

                                    <InputSwitch @click="necessaryRequired()" class="ml-auto mr-2"
                                        v-model="switchNecessary" />
                                </span>
                            </template>
                            <p class="m-0">
                                {{ $t('cookie_consent.neccessary_text') }}
                            </p>
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <span class="font-bold white-space-nowrap">Preferences</span>

                                    <InputSwitch class="ml-auto mr-2" v-model="switchPreferences" />
                                </span>
                            </template>
                            <p class="m-0">
                                {{ $t('cookie_consent.preferences_text') }}
                            </p>
                        </AccordionTab>
                        <AccordionTab>
                            <template #header>
                                <span class="flex align-items-center gap-2 w-full">
                                    <span class="font-bold white-space-nowrap">Statistics</span>

                                    <InputSwitch class="ml-auto mr-2" v-model="switchStatistics" />
                                </span>
                            </template>
                            <p class="m-0">
                                {{ $t('cookie_consent.statistics_text') }}
                            </p>
                        </AccordionTab>
                    </Accordion>

                </TabPanel>
                <TabPanel :header="$t('cookie_consent.about')">
                    <p class="m-0">
                        {{ $t('cookie_consent.about_text') }}
                    </p>
                </TabPanel>
            </TabView>
        </form>

        <template #footer>
            <Button :label="$t('cookie_consent.deny')" @click="denyCookies()" severity="secondary" outlined />
            <Button :label="$t('cookie_consent.allow_selection')" @click="allowSelection()" severity="secondary"
                outlined />
            <Button :label="$t('cookie_consent.allow_all')" @click="acceptAll()" severity="primary"></Button>
        </template>
    </Dialog>
</template>
<script>
export default {
    name: "cookieConsent",
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            cookieConsentDialog: false,
            switchNecessary: true,
            switchPreferences: false,
            switchStatistics: false
        }
    },
    created() {
        if (localStorage.getItem('cookieConsent')) {
            const cookieConsent = JSON.parse(localStorage.getItem('cookieConsent'));
            this.switchPreferences = cookieConsent.preferences;
            this.switchStatistics = cookieConsent.statistics;
        } else {
            setTimeout(() => {
                this.$emit('update:visible', true);
            }, 1500);
        }
    },
    methods: {
        acceptAll() {
            this.switchPreferences = true;
            this.switchStatistics = true;

            this.saveCookieConsent();

            this.$emit('update:visible', false);
        },
        allowSelection() {
            this.saveCookieConsent();
            this.$emit('update:visible', false);
        },
        denyCookies() {
            this.switchPreferences = false;
            this.switchStatistics = false;

            this.saveCookieConsent();
            this.$emit('update:visible', false);
        },
        necessaryRequired() {
            alert('Mandatory and cannot be deselected. Necessary cookies enable essential functions like page navigation and secure access, ensuring the website functions properly.');
            this.switchNecessary = !this.switchNecessary;
        },
        saveCookieConsent() {
            localStorage.setItem('cookieConsent', JSON.stringify({
                preferences: this.switchPreferences,
                statistics: this.switchStatistics
            }));

            // remove preferences if denied
            if (!this.switchPreferences) {
                localStorage.removeItem('readerPreferences');
            }
        }
    }
}
</script>