<template>

  <div class="z-5 sticky bg-white py-3 px-4 md:px-6 lg:px-8 flex align-items-center justify-content-start fixed w-full"
    v-on:scroll="scroll">
    <img @click="this.$router.push('/')" class="cursor-pointer" src="/images/logos/logo.png" alt="Image" height="30"
      width="30" />
    <span @click="this.$router.push('/')" class="cursor-pointer flex ml-2 text-xl font-medium align-item-left">Paperhub
      <sub class="ml-1 text-xs text-color-secondary">{{ $t('beta') }}</sub>
    </span>



    <!-- Burger Menu Icon for Small Screens -->
    <a class="mt-1 cursor-pointer block lg:hidden text-gray-400 justify-item-right ml-auto" v-styleclass="{
      selector: '@next',
      enterClass: 'hidden',
      leaveToClass: 'hidden',
      hideOnOutsideClick: true,
    }">
      <i class="pi pi-bars text-xl" style="color: black"></i>
    </a>


    <!-- Main Navigation Content -->
    <div
      class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-white left-0 top-100 px-6 lg:px-0 shadow-2 lg:shadow-none z-5">
      <section></section>

      <!-- Buttons Container -->
      <div class="flex flex-column lg:flex-row py-3 lg:py-0 mt-3 lg:mt-0">
        <!-- Login Button -->
        <Button v-if="!auth" :label="$t('navbar.login')" @click="this.$router.push('/login')" severity="contrast"
          class="p-button-text font-bold w-full lg:w-auto sm:p-button-outlined" size="small"></Button>
        <!-- Sign up Button -->
        <Button v-if="!auth" :label="$t('navbar.create_account')" @click="this.$router.push('/signup')"
          severity="contrast" class="mt-3 lg:mt-0 lg:ml-3 font-bold w-full lg:w-auto" size="small"></Button>

        <!-- Back to Library Button for Authenticated Users -->
        <Button v-if="auth" :label="$t('navbar.back_to_app')" @click="this.$router.push('/library')" icon="pi pi-book"
          severity="contrast" class="mt-3 lg:mt-0 lg:ml-3 font-bold w-full lg:w-auto"></Button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'navbar-layout',
  data() {
    return {
      auth: false,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.token = localStorage.getItem('token');

    if (this.token && this.user) {
      this.auth = true;
    }

    window.addEventListener('scroll', this.scroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    scroll() {
      if (window.scrollY > 0) {
        this.$el.classList.add('border-bottom-1');
        this.$el.classList.add('border-200');

      } else {
        this.$el.classList.remove('border-bottom-1');
        this.$el.classList.remove('border-200');

      }
    },
  },
};
</script>

<style scoped>
.navbar {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 100%;
  background-size: contain;
}

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
}
</style>