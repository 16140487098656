<template>
  <div class="surface-section flex align-content-end px-3 pt-5 md:px-6 lg:px-5 w-full" style="min-height: 200px">
    <div class="pt-5 border-top-1 border-300 w-full">
      <div class="grid">
        <!-- Paperhub -->
        <div class="col-12 sm:col-8 md:col-8 lg:col-5">
          <div class="pr-0 lg:pr-8">
            <img src="/images/logos/logo.png" alt="Image" height="50" />
            <p class="text-600 line-height-3 text-xl mb-0 mt-5">
              Paperhub - {{ $t('tagline') }}
            </p>
          </div>
        </div>

        <!-- Paperhub -->
        <div class="col-12 sm:col-6 md:col-4 lg:col-2">
          <span class="text-900 block font-bold line-height-3 mb-3">Paperhub</span>
          <ul class="list-none p-0 m-0">
            <li class="mb-2">
              <a @click="this.$router.push('/')" class="text-700 cursor-pointer line-height-3">{{ $t('footer.home')
                }}</a>
            </li>
            <!-- <li class="mb-2"><a @click="this.$router.push('/about')"
                                class="text-700 cursor-pointer line-height-3">About Us</a></li> -->
          </ul>
        </div>

        <!-- Support -->
        <div class="col-12 sm:col-6 md:col-4 lg:col-2">
          <span class="text-900 block font-bold line-height-3 mb-3">{{ $t('footer.support') }}</span>
          <ul class="list-none p-0 m-0">
            <li class="mb-2">
              <a @click="this.$router.push('/support')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.get_help') }}</a>
            </li>
            <li class="mb-2">
              <a @click="this.$router.push('/support#faq')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.faq') }}</a>
            </li>
          </ul>
        </div>

        <!-- Legal -->
        <div class="col-12 sm:col-6 md:col-4 lg:col-2">
          <span class="text-900 block font-bold line-height-3 mb-3">{{ $t('footer.legal') }}</span>
          <ul class="list-none p-0 m-0">
            <li class="mb-2">
              <a @click="this.$router.push('/tos')" class="text-700 cursor-pointer line-height-3">{{ $t('footer.terms')
                }}</a>
            </li>
            <li class="mb-2">
              <a @click="this.$router.push('/privacy')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.privacy') }}</a>
            </li>
            <li class="mb-2">
              <a @click="this.$router.push('/cookies')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.cookies') }}</a>
            </li>
            <li class="mb-2">
              <a @click="this.$router.push('/imprint')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.imprint') }}</a>
            </li>
            <li class="mb-2">
              <a @click="this.$router.push('/attributions')" class="text-700 cursor-pointer line-height-3">{{
                $t('footer.attributions') }}</a>
            </li>
          </ul>
        </div>
      </div>


      <div class="flex border-top-1 border-300 justify-content-between align-items-center py-5">
        <span>&copy; 2024 Paperhub - {{ $t('footer.all_rights_reserved') }}</span>
        <div class="flex align-items-center justify-content-between">
          <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" :placeholder="lang_placeholder"
            @update:modelValue="changeLanguage" class="w-full md:w-14rem">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>{{ slotProps.value.name }}</div>
              </div>
              <span v-else>
                {{ lang_placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>{{ slotProps.option.name }}</div>
              </div>
            </template>
          </Dropdown>

          <a href="https://www.linkedin.com/company/paperhubapp" target="_blank"
            class="cursor-pointer text-500 md:ml-3 ml-2">
            <i class="pi pi-linkedin text-xl"></i>
          </a>

          <a href="https://x.com/paperhubso" target="_blank" class="cursor-pointer text-500 md:ml-3 ml-2">
            <i class="pi pi-twitter text-xl"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      lang_placeholder: '',
      selectedLanguage: null,
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Deutsch', code: 'de' },
        { name: 'Español (Preview)', code: 'es' },
        { name: 'Français (Preview)', code: 'fr' },
        { name: '中文 (Preview)', code: 'zh' },
        // { name: '日本語 (Preview)', code: 'ja' },
        { name: '한국어 (Preview)', code: 'ko' }
      ]
    };
  },
  created() {
    this.setInitialLanguage();
  },
  methods: {
    setInitialLanguage() {
      // Check cookie for language preference
      const cookieLang = this.getCookie('lang');
      const currentLocale = cookieLang || this.$i18n.locale;

      // Set placeholder to the current language
      this.lang_placeholder = this.languages.find(lang => lang.code === currentLocale.split('-')[0])?.name || 'Select language';

      // Set the selected language based on current locale
      this.selectedLanguage = this.languages.find(lang => lang.code === currentLocale) || null;
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage.code;
      document.cookie = `lang=${this.selectedLanguage.code};path=/`;
    },
    getCookie(name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) return match[2];
      return null;
    }
  }
};
</script>
