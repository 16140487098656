/* eslint-disable no-unused-vars */
import Vuex from 'vuex';
import AuthService from '@/services/AuthService';
import router from '@/router';
import axios from 'axios';
import BookmarkService from '@/services/BookmarkService';

const authService = new AuthService();

export default new Vuex.Store({
  state: {
    token: null,
    loggingIn: false,
    signingUp: false,
    signupError: null,
    signupSuccessful: false,
    loginError: null,
    user: null,
    passwordResetMailSent: false,
    passwordResetMailError: null,
    bookmarks: [],
    bookmarkGroups: [],
    readerFontSize: 18,
    readerFontFamily: 'Georgia',
    readerColor: 'light',
    preBackground: '#f5f5f5',
    preBorder: '#ddd',
    codeBackground: '#f5f5f5',
    blockquoteBorder: '#666',
  },
  mutations: {
    SET_READER_COLOR(state, color) {
      state.readerColor = color;

      if (color === 'light' || color === 'sepia') {
        state.preBackground = '#f5f5f5';
        state.preBorder = '#ddd';
        state.codeBackground = '#f5f5f5';
        state.blockquoteBorder = '#666';
      } else {
        state.preBackground = '#333';
        state.preBorder = '#444';
        state.codeBackground = '#333';
        state.blockquoteBorder = '#999';
      }
    },
    SET_READER_FONT_SIZE(state, fontSize) {
      state.readerFontSize = fontSize;
    },
    SET_READER_FONT_FAMILY(state, fontFamily) {
      state.readerFontFamily = fontFamily;
    },
    SET_BOOKMARKS(state, bookmarks) {
      state.bookmarks = bookmarks;
    },
    SET_BOOKMARK_GROUPS(state, bookmarkGroups) {
      state.bookmarkGroups = bookmarkGroups;
    },
    loginStart: (state) => (state.loggingIn = true),
    loginStop: (state, errorMessage) => {
      state.loggingIn = false;
      state.loginError = errorMessage;
      state.loginSuccessful = !errorMessage;
    },
    signupStart: (state) => (state.signingUp = true),
    signupStop: (state, errorMessage) => {
      state.signingUp = false;
      state.signupError = errorMessage;
      state.signupSuccessful = !errorMessage;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    PwResetMailSent(state) {
      state.passwordResetMailSent = true;
    },
    setPwResetMessage(state, msg) {
      state.passwordResetMailError = msg;
    },
  },
  actions: {
    async doSignup({ commit }, signupData) {
      commit('signupStart');

      const { name, email, password } = signupData;

      try {
        const res = await authService.register(name, email, password);

        if (res) {
          commit('signupStop', null);
          commit('signupSuccessful', true);
        } else {
          commit('signupStop', res.data.error);
          commit('signupSuccessful', false);
        }
      } catch (error) {
        commit('signupStop', error.response.data.error);
      }
    },
    async doLogin({ commit }, loginData) {
      commit('loginStart');

      const { email, password, next } = loginData;

      try {
        const res = await authService.login(email, password);

        if (res && res.auth) {
          localStorage.setItem('token', res.token);
          try {
            const user = JSON.stringify(
              await authService.fetchUserProfile(res.token)
            );

            // get gravatar
            // sha256 hash of email with window.crypto.subtle.digest
            const hash = await window.crypto.subtle.digest(
              'SHA-256',
              new TextEncoder().encode(email)
            );

            // convert hash to string
            const hashArray = Array.from(new Uint8Array(hash));
            const hashHex = hashArray
              .map((b) => b.toString(16).padStart(2, '0'))
              .join('');

            // axios request to gravatar api
            try {
              const response = await axios.get(
                `https://api.gravatar.com/v3/profiles/${hashHex}`
              );
              localStorage.setItem('avatar_url', response.data.avatar_url);
            } catch (error) {
              next;
            }

            commit('setToken', res.token);
            commit('setUser', user);
            localStorage.setItem('user', user);
            localStorage.setItem(
              'expiresAt',
              Date.now() + res.expiresIn * 1000 // Convert to milliseconds
            );
            document.cookie = `accessToken=${res.accessToken}; max-age=31536000`; // 1 year max-age

            commit('loginStop', null);
            router.push(next || '/library');
          } catch (error) {
            // remove token
            localStorage.removeItem('token');
            commit('loginStop', error.response.data.error);
          }
        } else {
          commit('loginStop', 'Invalid credentials');
          commit('setToken', null);
        }
      } catch (error) {
        commit(
          'loginStop',
          error.response.data.error || 'An error occurred. Please try again.'
        );
      }
    },
    async doRequestPWReset({ commit }, data) {
      const { email } = data;

      authService
        .requestPasswordReset(email)
        .then((res) => {
          commit('PwResetMailSent');
        })
        .catch((error) => {
          commit('setPwResetMessage', error);
        });
    },

    doLogout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('avatar_url');
      localStorage.removeItem('expiresIn');

      document.cookie = 'kindle=; max-age=0';
      document.cookie = 'accessToken=; max-age=0';

      commit('setToken', null);
      commit('setUser', null);
      router.push('/login');
    },
    fetchAccessToken({ commit }) {
      commit('setToken', localStorage.getItem('token'));
    },
    fetchUserProfile({ commit }) {
      commit('setUser', JSON.parse(localStorage.getItem('user')));
    },

    async fetchBookmarks({ commit }) {
      const bookmarkService = new BookmarkService(this.state.token);

      const bookmarks = await bookmarkService.getBookmarks();
      commit('SET_BOOKMARKS', bookmarks);
    },

    async fetchBookmarkGroups({ commit }) {
      const bookmarkService = new BookmarkService(this.state.token);

      const bookmarkGroups = await bookmarkService.getBookmarkGroups();
      commit('SET_BOOKMARK_GROUPS', bookmarkGroups);
    },

    async fetchBookmarkGroup({ commit }, groupId) {
      const bookmarkService = new BookmarkService(this.state.token);

      const bookmarkGroups = await bookmarkService.getBookmarkGroups();
      commit('SET_BOOKMARK_GROUPS', bookmarkGroups);
    },

    async refreshBookmarks({ dispatch }) {
      dispatch('fetchBookmarks'); // Refresh the bookmarks
    },

    async enlargeFont({ commit }) {
      commit('SET_READER_FONT_SIZE', this.state.readerFontSize + 2);
    },

    async reduceFont({ commit }) {
      commit('SET_READER_FONT_SIZE', this.state.readerFontSize - 2);
    },

    async changeFontFamily({ commit }, fontFamily) {
      commit('SET_READER_FONT_FAMILY', fontFamily);
    },
  },
});
