import { createRouter, createWebHistory } from 'vue-router';

import Library from '../views/LibraryView';
import Signup from '../views/auth/SignupView';
import Login from '../views/auth/LoginView';
import ActivateUserView from '@/views/auth/ActivateUserView.vue';
import Settings from '../views/auth/SettingsView';
import PasswortResetRequest from '../views/auth/PasswortResetRequestView';
import ResetPassword from '../views/auth/ResetPasswordView';
import NotFound from '@/views/error/404View.vue';
import Landing from '@/views/LandingView';
import Support from '@/views/SupportView';

// Legal
import TermsView from '@/views/legal/TermsView.vue';
import PrivacyView from '@/views/legal/PrivacyView.vue';
import CookiePolicyView from '@/views/legal/CookiePolicyView.vue';
import ImprintView from '@/views/legal/ImprintView.vue';
import AttributionsView from '@/views/legal/AttributionsView.vue';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: false },
  },
  {
    path: '/',
    name: 'home',
    component: Landing,
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: { title: 'Support' },
  },
  {
    path: '/tos',
    name: 'tos',
    component: TermsView,
    meta: { title: 'Terms of Service' },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: { title: 'Privacy Policy' },
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiePolicyView,
    meta: { title: 'Cookie Policy' },
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: ImprintView,
    meta: { title: 'Imprint' },
  },
  {
    path: '/attributions',
    name: 'attributions',
    component: AttributionsView,
    meta: { title: 'Attributions' },
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: { requiresAuth: false, authPage: true, title: 'Sign up' },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false, authPage: true, title: 'Login' },
  },
  {
    path: '/activate/',
    name: 'activate-user',
    component: ActivateUserView,
    meta: { requiresAuth: false, authPage: true, title: 'Activate User' },
  },
  {
    path: '/password-reset',
    name: 'password-reset-request',
    component: PasswortResetRequest,
    meta: { requiresAuth: false, title: 'Password Reset' },
  },
  {
    path: '/password-reset/change/',
    name: 'password-reset',
    component: ResetPassword,
    meta: { requiresAuth: false, title: 'Password Reset' },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true, title: 'Settings' },
  },
  {
    path: '/library',
    name: 'library',
    requiresAuth: true,
    component: Library,
    meta: { requiresAuth: true, title: 'Library' },
  },
  {
    path: '/library/:bookId',
    name: 'book',
    props: true,
    component: () => import('../views/BookView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/sync/',
    name: 'sync',
    exact: true,
    component: () => import('../views/SyncView.vue'),
    meta: { requiresAuth: true, title: 'Sync' },
  },
  {
    path: '/bookmarks/:id?',
    name: 'bookmarks',
    exact: true,
    component: () => import('../views/BookmarksView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/share/group/:id',
    name: 'share-group',
    exact: true,
    component: () => import('../views/share/ShareGroupView.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/reader/:id',
    name: 'reader',
    component: () => import('../views/ReaderView.vue'),
    meta: { requiresAuth: true, title: 'Reader' },
  },

  // notion oauth2-callback
  {
    path: '/sync/callback/notion',
    name: 'notion-oauth2-callback',
    component: () => import('@/views/oauth2/NotionCallbackView.vue'),
    meta: { requiresAuth: false }, // set this to true!!!!
  },

  // evernote oauth2-callback
  {
    path: '/sync/callback/evernote',
    name: 'evernote-oauth2-callback',
    component: () => import('@/views/oauth2/EvernoteCallbackView.vue'),
    meta: { requiresAuth: false }, // set this to true!!!!
  },

  // pocket oauth2-callback
  {
    path: '/sync/callback/pocket',
    name: 'pocket-oauth2-callback',
    component: () => import('@/views/oauth2/PocketCallbackView.vue'),
    meta: { requiresAuth: false }, // set this to true!!!!
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Helper function to determine if the token has expired
function expired() {
  const expiresAt = localStorage.getItem('expiresAt');
  if (!expiresAt) {
    return true; // Assume expired if no expiration time is set
  }

  // Check if the current time is past the expiration time or within 2 days of expiration
  const timeRemaining = expiresAt - Date.now();

  if (timeRemaining <= 0) {
    // Less than 0 or less than 2 days
    return true;
  }

  console.log('Token is valid.');
  return false;
}

// Function to clear authentication data
function clearAuthData() {
  console.log('Clearing auth data');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('avatar_url');
  localStorage.removeItem('expiresIn');
  document.cookie = 'accessToken=; max-age=0';
  document.cookie = 'kindle=; max-age=0';
}

// beforeEach Navigation Guard
router.beforeEach(async (to) => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  // Set the page title
  window.document.title =
    to.meta && to.meta.title ? 'Paperhub - ' + to.meta.title : 'Paperhub';

  // Scroll to top on navigation
  window.scrollTo(0, 0);

  // Redirect authenticated users trying to access the home page
  if (to.path === '/' && token && user) {
    return { path: '/library' };
  }

  // Authentication check for routes that require authentication
  // If the route requires authentication, check conditions
  if (to.meta.requiresAuth && (!token || !user || expired())) {
    clearAuthData();
    return { path: '/login', query: { next: to.path } };
  }
});

export default router;
