<template>
    <div class="container" style="height: 100vh;">
        <div class="row" style=" margin-top: 5%">
            <div class=" flex align-items-center justify-content-center  align-self-center
        ">
                <Card style="width: 30rem; " breakpoints="1199px: 75vw, 575px: 90vw" class="shadow-2">
                    <template #content>
                        <div class="text-center mb-5">
                            <img @click="this.$router.push('/')" src="images/logos/logo.png" alt="Image" height="50"
                                class="mb-3 cursor-pointer">
                            <div class="text-900 text-3xl font-medium mb-3">{{ $t('resetPWRequestPage.title') }}</div>
                            <span class="text-600 font-medium line-height-3">{{ $t('resetPWRequestPage.descr') }}</span>
                        </div>

                        <!-- Success Alert -->
                        <Message v-if="passwordResetMailSent" severity="success">{{ $t('resetPWRequestPage.email_sent')
                            }}
                        </Message>

                        <Message v-if="passwordResetMailError" severity="error">{{ $t('resetPWRequestPage.send_failed')
                            }}
                        </Message>

                        <form @submit.prevent="resetPassword">
                            <label for="email1" class="block text-900 font-medium mb-2">{{
                                $t('resetPWRequestPage.email')
                                }}</label>
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" />

                            <Button type="submit" :label="$t('resetPWRequestPage.send')" class="w-full"></Button>
                        </form>
                    </template>
                </Card>
            </div>
            <div class="row">
                <!-- Legal Links -->
                <authFooter />
            </div>
        </div>
    </div>

</template>
<script>
import { mapActions, mapState } from 'vuex';
import Card from 'primevue/card';
import authFooter from '@/components/legal/authFooter.vue';

export default {
    name: 'reset-view',
    components: {
        Card,
        authFooter
    },
    data: () => {
        return {
            email: "",
        };
    },
    computed: {
        ...mapState({
            passwordResetMailSent: state => state.passwordResetMailSent,
            passwordResetMailError: state => state.passwordResetMailError
        })
    },
    methods: {
        ...mapActions(['doRequestPWReset']),

        resetPassword(e) {
            e.preventDefault();

            this.doRequestPWReset({ email: this.email })

        }
    },
};
</script>

<style scoped>
html {
    min-height: 100%;
    /* fill the screen height to allow vertical alignement */
    display: grid;
    /* display:flex works too since body is the only grid cell */
}

body {
    margin: auto;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full height of the viewport */
    width: 100vw;
    /* Full width of the viewport */
    position: relative;
    /* No need for absolute positioning */

}
</style>