<template>
    <div class="flex align-items-center justify-content-center flex-wrap card-container align-self-center mt-7">

        <Card style="width: 30rem" breakpoints="1199px: 75vw, 575px: 90vw">
            <template #content>
                <div class="text-center mb-5">
                    <img @click="this.$router.push('/')" src="/images/logos/logo.png" alt="Image" height="50"
                        class="mb-3 cursor-pointer">
                    <div class="text-900 text-3xl font-medium mb-3">Account Activation</div>
                </div>

                <Message v-if="activateSuccess" severity="success">{{ activateSuccess }}</Message>

                <Message v-if="activateError" severity="error">{{ activateError }}</Message>

                <Button type="submit" @click="this.$router.push('/login')" label="Login Now" class="w-full"></Button>
            </template>
        </Card>
    </div>
    <!-- Legal links -->
    <div class="flex align-items-center justify-content-center flex-wrap align-self-center">
        <Button label="Support" @click="this.$router.push('/support')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button label="Privacy Policy" @click="this.$router.push('/privacy')" severity="contrast"
            class="p-button-text text-sm"></Button>
        <Button label="Terms of Service" @click="this.$router.push('/tos')" severity="contrast"
            class="p-button-text text-sm"></Button>
    </div>

</template>
<script>
import Card from 'primevue/card';
import AuthService from '@/services/AuthService'

const authService = new AuthService();

export default {
    name: 'activate-user-view',
    components: {
        Card,
    },
    data: () => {
        return {
            activateSuccess: null,
            activateError: null
        };
    },
    created() {
        authService.activate(
            this.$route.query.id,
            this.$route.query.token
        ).then((res) => {
            this.activateSuccess = res
        }).catch((error) => {
            this.activateError = error.response.data
        })
    },
};
</script>

<style scoped>
.center-screen {
    position: relative;
    /* width: 600px;
    height: 250px; */
    width: 70%;
    height: 20%;
    z-index: 15;
    top: 30%;
    left: 50%;
    margin: -100px 0 0 -150px;

}
</style>